import { gql } from '@apollo/client';
import { USER } from './constQuery';

export const userProgressFragmentGql = gql`
  fragment progress on UsersPermissionsUser {
    dashboardPassed
    feedPassed
    onboardingPassed
    learningPassed
    knowledgeBasePassed
  }
`;

export const userSavedsFragmentGql = gql`
  fragment saveds on UsersPermissionsUser {
    saveds {
      id
      article {
        id
      }
    }
  }
`;

export const userFollowFragmentGql = gql`
  fragment follow on UsersPermissionsUser {
    followers {
      id
    }
    interests {
      id
    }
    followings {
      id
    }
  }
`;

export const userDataFragmentGql = USER;

export const likesFragmentGql = gql`
  fragment likes on Article {
    likes {
      id
    }
  }
`;

export const organizationsFragmentGql = gql`
  fragment organizations on UsersPermissionsUser {
    organizations {
      id
      name
    }
    learningPassed
  }
`;

export const departmentsFragmentGql = gql`
  fragment departments on UsersPermissionsUser {
    departments {
      id
      name
    }
  }
`;

export const followingsFollowersFragmentGql = gql`
  fragment followingsFollowers on UsersPermissionsUser {
    followings {
      id
      username
    }
    followers {
      id
      username
    }
  }
`;

export const interestsFragmentGql = gql`
  fragment interests on UsersPermissionsUser {
    interests {
      id
    }
  }
`;

export const eventsFragment = gql`
  fragment events on UsersPermissionsUser {
    events {
      id
      name
    }
  }
`;
