import React from 'react';
import { Drawer } from 'antd';
import Svg from '../SVG';

const DrawerModal = (props) => {
  const {
    isBurgerMenu,
    width,
    placement,
    title,
    isDrawerVisible,
    className,
    handleClick,
    children,
  } = props;

  return (
    <Drawer
      title={title}
      placement={placement}
      width={width}
      onClose={handleClick}
      visible={isDrawerVisible}
      closeIcon={<Svg name={isBurgerMenu ? 'menu-expanded' : 'close'} />}
      className={className}
    >
      {children}
    </Drawer>
  );
};

export default DrawerModal;
