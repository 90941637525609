import { RESET_SHOW_CONGRATS, SHOW_CONGRATS } from '../actions/confettiAction';

const initialState = {
  showCongrats: false,
};

export default function confetti(state = initialState, action) {
  switch (action.type) {
    case SHOW_CONGRATS:
      return {
        ...state,
        showCongrats: true,
      };
    case RESET_SHOW_CONGRATS:
      return initialState;
    default:
      return state;
  }
}
