import React from 'react';
import cx from 'classnames';
import Svg from '../../common/SVG';

const Answer = ({
  index,
  answerText,
  correctDescription,
  incorrectDescription,
  handleClick,
  checked,
  incorrectAnswer,
  correctAnswer,
  notSelectedAnswer,
}) => {
  const answerClassResult = cx('answer-item', {
    'answer-item--checked': checked && !incorrectAnswer && !correctAnswer && !notSelectedAnswer,
    'answer-item--correct': correctAnswer,
    'answer-item--incorrect': incorrectAnswer,
    'answer-item--not-selected-correct': notSelectedAnswer,
  });

  return (
    <li className={answerClassResult} onClick={handleClick} key={index}>
      <div className="answer-round">
        {incorrectAnswer && <Svg name="close" />}
        {correctAnswer && <Svg name="checkmark" />}
      </div>
      <span className="answer-label">{answerText}</span>
    </li>
  );
};

export default Answer;
