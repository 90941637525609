import React, { useEffect, useState } from 'react';
import Tab from '../../common/Tab/Tab';
import ShowMore from '../../common/ShowMore/ShowMore';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';
import { instanceFetch } from '../../helper/instanceFetch';
import useUserFollow from '../../common/useUserFollow';

const FeedSidebarTopics = () => {
  const token = useSelector(tokenSelector);
  const user = useSelector(userSelector);
  const countTopicsToShow = 5;
  const [isTopicsShowMore, setTopicsShowMore] = useState(false);

  const { onClickFollow, onClickUnFollow } = useUserFollow();

  const [topics, setTopics] = useState([]);

  const ROUTES = {
    TOPICS: `${process.env.REACT_APP_API_URL}/api/topics`,
  };

  useEffect(() => {
    instanceFetch
      .get(ROUTES.TOPICS, { authorization: `Bearer ${token}` })
      .then((response) => {
        setTopics(response.data);
      })
      .catch((error) => {
        console.error('Error fetching topics:', error);
      });
  }, []);

  if (!topics) {
    return null;
  }

  const activeItems = isTopicsShowMore ? topics : topics.slice(0, countTopicsToShow);

  return (
    <>
      {activeItems.map(({ id, name }) => {
        const isFollowing = user?.topics && user?.topics.some((following) => following.id === id);
        return (
          <Tab
            key={id}
            typeRight="follow"
            onClickFollow={() => (isFollowing ? onClickUnFollow(id) : onClickFollow(id))}
            textLeft={name}
            iconLeft="topic-filled"
            btnFollowLabel
            active={isFollowing}
            color="transparentNone"
          />
        );
      })}
      {topics.length > countTopicsToShow && (
        <ShowMore
          isShowMore={isTopicsShowMore}
          setShowMore={setTopicsShowMore}
          openLabel="Show less topics"
          closeLabel="Show more topics"
        />
      )}
    </>
  );
};
export default FeedSidebarTopics;
