import React from 'react';
import ArticleRelated from '../types/ArticleRelated';
import ArticleOpenedFooter from '../ArticleOpenedFooter';
import { isEmpty } from 'lodash';

const ArticleDefaultFooter = ({ article, type }) => {
  return (
    <>
      <ArticleOpenedFooter article={article} type={type} />
      {!isEmpty(article?.relatedArticles) && (
        <div className="article-opened__related-articles-wrapper">
          <div className="m-5">Related publications</div>
          <div className="article-opened__related-articles-list">
            {article?.relatedArticles?.slice(0, 2).map((article, index) => {
              return (
                <ArticleRelated
                  key={article.id}
                  article={article}
                  sizeToShow="sm"
                  hideBookmark={!article?.saved}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleDefaultFooter;
