import React from 'react';
import DropdownCheckboxPage from '../../components/Dropdown/DropdownCheckboxPage';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
const PageTemplateArticleFormatsFilter = ({
  labelFormats,
  arrayFormats,
  selectedFormats,
  onClickFormats,
  labelSort,
  arraySort,
  selectedSort,
  onClickSort,
  position = 'left',
}) => {
  return (
    <div className="page-template__filter">
      <DropdownCheckboxPage
        label={labelFormats}
        array={arrayFormats}
        selected={selectedFormats}
        position={position}
        onClick={onClickFormats}
      />
      <DropdownLabelPage
        label={labelSort}
        array={arraySort}
        selected={selectedSort}
        position={position}
        onClick={onClickSort}
      />
    </div>
  );
};

export default PageTemplateArticleFormatsFilter;
