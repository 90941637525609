import React, { useCallback, useState } from 'react';
import FinishReviewEmoji from './FinishReviewEmoji';

const rateArray = [
  {
    icon: '&#128530;',
    label: 'Bad',
    value: 1,
  },
  {
    icon: '&#128529;',
    label: 'Boring',
    value: 2,
  },
  {
    icon: '&#128528;',
    label: 'So so',
    value: 3,
  },
  {
    icon: '&#128578;',
    label: 'Good',
    value: 4,
  },
  {
    icon: '&#128525;',
    label: 'Loved it!',
    value: 5,
  },
];
const FinishReviewEmojiList = ({ rating, setRating }) => {
  return (
    <div className="finish-review-emoji">
      <div className="lesson-quiz__card-description">How would you rate the lesson?</div>
      <div className="finish-review-emoji__list">
        {rateArray.map((item, index) => {
          return (
            <FinishReviewEmoji
              key={index}
              item={item}
              active={item.value === rating}
              setRating={setRating}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FinishReviewEmojiList;
