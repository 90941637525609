import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/styles.scss';
import AppLayout from './AppLayout';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppLayout />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
