import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import ArticleProgressRow from '../../../components/Articles/types/ArticleProgressRow';
import { sectionHeader } from '../Dashboard';
import Button from '../../../common/Button/Button';
import { useHistory } from 'react-router-dom';

const DashboardCourse = ({ courses }) => {
  const { push } = useHistory();
  const isEmptyCourses = isEmpty(courses);

  const renderEmptyCourses = useMemo(() => {
    return (
      <div className="dashboard__courses-empty">
        <img className="empty-articles-title__img" alt="empty" src="/images/suitcase.svg" />
        <div className="dashboard__courses-empty-title">You haven’t started any courses yet</div>
        <Button
          text="Browse courses"
          type="primary"
          size="md"
          onClick={() => push('/dashboard/your-learnings')}
        />
      </div>
    );
  }, [courses]);

  const renderCourses = useMemo(() => {
    const coursesLength = courses.length;
    return (
      <div className="dashboard__courses-content">
        {courses.length > 0 &&
          courses
            .slice(0, Math.min(coursesLength === 3 ? coursesLength : 2, coursesLength))
            .map((course) => (
              <ArticleProgressRow key={course.id} course={course} sizeToShow="md" />
            ))}
        {coursesLength > 3 && (
          <div className="dashboard__courses-content-more">
            <Button
              text="Browse more courses"
              type="primary"
              size="md"
              onClick={() => push('/dashboard/your-learnings')}
            />
          </div>
        )}
      </div>
    );
  }, [courses]);

  const disableSeeAll = isEmptyCourses || courses.length < 1 || courses.length > 3;

  if (isEmptyCourses) return renderEmptyCourses;

  return (
    <div className="dashboard__courses dashboard-box">
      {sectionHeader('Your learning', '/dashboard/your-learnings', disableSeeAll)}
      {renderCourses}
    </div>
  );
};

export default DashboardCourse;
