import React, { useCallback } from 'react';
import cx from 'classnames';

const FinishReviewEmoji = ({ item, setRating, active }) => {
  const reviewClass = cx('finish-review-emoji__item', {
    active: active,
  });

  const activeHandler = useCallback(() => {
    setRating(item.value);
  }, [item]);

  return (
    <div className={reviewClass} onClick={activeHandler}>
      <div className="finish-review-emoji__icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
      <div className="finish-review-emoji__label">{item.label}</div>
    </div>
  );
};

export default FinishReviewEmoji;
