import React from 'react';
import { Select } from 'antd';
import Label from '../Label/Label';

const { Option } = Select;

const SelectComponent = (props) => {
  const { options, labelText, placeholder, id, error, prompt, mode, value, onChange } = props;

  return (
    <div className="field">
      {labelText && <Label id={id} text={labelText} />}

      <Select
        mode={mode && mode}
        placeholder={placeholder}
        className="field__select"
        id={id}
        onChange={onChange}
        value={value}
      >
        {options.map((item) => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>

      {!error && prompt && <div className="field__prompt">{prompt}</div>}
      {error && <div className="field__error">{error}</div>}
    </div>
  );
};

export default SelectComponent;
