import React, { Fragment } from 'react';
import cx from 'classnames';
import Button from '../../common/Button/Button';
import Masonry from 'react-masonry-css';
import ArticlesItem from '../../components/Articles/ArticlesItem';

const itemsArr = new Array(12).fill('');

const EmptyDashedArticleGrid = ({ masonry, sheet, image, title, btn, btnIcon, articles }) => {
  const isMobile = false;

  const gridClass = cx(
    masonry && 'empty-articles-grid',
    sheet && 'empty-cards-grid',
    isMobile && 'empty-articles-mobile'
  );

  if (articles && articles.length) {
    return (
      <Masonry
        breakpointCols={2}
        className="masonry-articles-list"
        columnClassName="masonry-articles-col"
      >
        {articles.map((item) => (
          <ArticlesItem
            key={item.id}
            {...item}
            size={(masonry || isMobile) && 'xs'}
            classic
            personal
          />
        ))}
      </Masonry>
    );
  }

  return (
    <div className={gridClass}>
      {masonry ? (
        isMobile ? (
          <div className="empty-articles-grid-mobile">
            <div className="empty-articles-grid__item" />
          </div>
        ) : (
          <>
            <div className="empty-articles-grid-left">
              <div className="empty-articles-grid__item" />
              <div className="empty-articles-grid__item" />
            </div>
            <div className="empty-articles-grid-right">
              <div className="empty-articles-grid__item" />
              <div className="empty-articles-grid__item" />
            </div>
          </>
        )
      ) : isMobile ? (
        <div className="empty-articles-grid-mobile">
          <div className="empty-articles-grid__item" />
        </div>
      ) : (
        itemsArr.map((value, index) => {
          return <Fragment key={index}>{value}</Fragment>;
        })
      )}

      <div className="empty-articles-title">
        <img className="empty-articles-title__img" alt="empty" src={`/images/${image}`} />
        <span className="empty-articles-title__label">{title}</span>
        {btn && (
          <Button
            type="primary"
            size={isMobile ? 'md' : 'lg'}
            text={btn}
            iconLeft={btnIcon && 'plus'}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyDashedArticleGrid;
