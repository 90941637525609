import { useCallback } from 'react';
import { instanceFetch } from '../helper/instanceFetch';
import { setUserInfoAction } from '../store/actions/getUserInfoAction';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';

const useUserFollow = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const onClickFollow = useCallback((id) => {
    const ROUTES = {
      PUBLISHERS_FOLLOW: `${process.env.REACT_APP_API_URL}/api/me/user/follow/${id}`,
    };
    instanceFetch
      .post(ROUTES.PUBLISHERS_FOLLOW, {}, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(setUserInfoAction(response));
      })
      .catch((error) => {
        console.error('Error follow publishers:', error);
      });
  }, []);

  const onClickUnFollow = useCallback((id) => {
    const ROUTES = {
      PUBLISHERS_UNFOLLOW: `${process.env.REACT_APP_API_URL}/api/me/user/unfollow/${id}`,
    };
    instanceFetch
      .post(ROUTES.PUBLISHERS_UNFOLLOW, {}, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(setUserInfoAction(response));
      })
      .catch((error) => {
        console.error('Error unfollow publishers:', error);
      });
  }, []);

  return { onClickFollow, onClickUnFollow };
};

export default useUserFollow;
