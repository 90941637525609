import React, { useMemo, useState } from 'react';
import UserOnboardingFirstStep from './UserOnboardingFirstStep';
import UserOnboardingSecondStep from './UserOnboardingSecondStep';
import UserOnboardingThirdStep from './UserOnboardingThirdStep';
import { Link } from 'react-router-dom';

const ONBOARDING_STEP = [
  UserOnboardingFirstStep,
  UserOnboardingSecondStep,
  UserOnboardingThirdStep,
];

const UserOnboarding = () => {
  const [activeStep, setActiveStep] = useState(0);

  const Content = useMemo(() => ONBOARDING_STEP[activeStep], [activeStep]);

  return (
    <div className="onboarding">
      <Link className="login-logo" to="/">
        <img className="login-logo__img" src="/images/logo.png" alt="logo" />
      </Link>

      <div className="onboarding__inner">
        <div className="onboarding-steps">
          {ONBOARDING_STEP.map((_, index) => (
            <div
              key={index}
              className={`onboarding-steps__item ${index <= activeStep ? 'active' : ''}`}
            >
              <div className="onboarding-steps__circle">{index + 1}</div>
            </div>
          ))}
        </div>

        <div className="onboarding__content">
          <Content handleStep={setActiveStep} />
        </div>
      </div>
    </div>
  );
};

export default UserOnboarding;
