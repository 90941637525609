import { useCallback } from 'react';
import { instanceFetch } from './instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';
import { getCourseAction } from '../store/actions/getCourseAction';

export const useGetCourseBySlug = ({ slug }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    COURSE: `${process.env.REACT_APP_API_URL}/api/courses/slug/${slug}`,
  };

  return useCallback(() => {
    instanceFetch
      .get(ROUTES.COURSE, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getCourseAction(response));
      })
      .catch((error) => {
        console.error('Error fetching course:', error);
      });
  }, []);
};
