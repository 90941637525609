import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { signIn } from '../../helper';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfoAction, setUserInfoAction } from '../../store/actions/getUserInfoAction';
import { setTokenAction } from '../../store/actions/setTokenAction';
import { userSelector } from '../../store/selectors';
import { instanceFetch } from '../../helper/instanceFetch';
import { logoutAction } from '../../store/actions/logoutAction';

const Oauth = () => {
  const location = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    signIn((user, token) => {
      instanceFetch
        .get(`${process.env.REACT_APP_API_URL}/api/me`, { authorization: `Bearer ${token}` })
        .then((response) => {
          dispatch(setUserInfoAction(response));
          if (response?.progress?.onboardingPassed) {
            push('/dashboard');
          } else {
            push('/onboarding');
          }
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
          dispatch(logoutAction());
          push('/');
        });

      dispatch(setTokenAction(token));
    }, location.search);
  }, []);
  return null;
};

export default Oauth;
