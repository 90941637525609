import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookmarksSelector } from '../../../../store/selectors';
import SubPagesFilterAndContent from '../../../../common/PageTemplate/SubPagesFilterAndContent';
import { useGetBookmarks } from '../../../../helper/getBookmarks';
import { isEmpty } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';

const SavedPage = () => {
  const { pathname } = useLocation();
  const bookmarks = useSelector(bookmarksSelector);
  const handleGetBookmarks = useGetBookmarks();
  useEffect(() => {
    handleGetBookmarks();
  }, []);

  const renderContent = useMemo(() => {
    if (isEmpty(bookmarks) || !bookmarks) {
      return (
        <div className="saved-page-empty">
          <span>You don't have any saved articles yet.</span>
          <span>
            Go to <a href="/dashboard">Dashboard</a>
          </span>
        </div>
      );
    }
    console.log('bookmarks', bookmarks);
    return (
      <SubPagesFilterAndContent
        articles={Object.entries(bookmarks).map(([key, value]) => value)}
        getBookmarks={handleGetBookmarks}
      />
    );
  }, [bookmarks]);

  if ((isEmpty(bookmarks) || !bookmarks) && pathname === '/saved') {
    console.log('redirect');
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }
  return (
    <div className="page-template__container">
      <div className="l-2">Saved</div>
      {renderContent}
    </div>
  );
};

export default SavedPage;
