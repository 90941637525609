import React from 'react';
import SidebarAuthNav from './SidebarAuthNav';
import { Link } from 'react-router-dom';
import SidebarKnowledgeBase from './SidebarKnowledgeBase';
import SidebarLearningDevelopment from './SidebarLearningDevelopment';

const SidebarAuthMobile = () => {
  return (
    <div className="sidebar auth mobile">
      <Link className="sidebar-logo" to="/dashboard">
        <img className="sidebar-logo__img" src="/images/circle.io-logo.svg" alt="logo" />
      </Link>
      <SidebarAuthNav />

      <div className="sidebar-sub-nav-list">
        <span className="sidebar-sub-nav-title">Knowledge base</span>
        <SidebarKnowledgeBase />
        <SidebarLearningDevelopment />
      </div>
    </div>
  );
};

export default SidebarAuthMobile;
