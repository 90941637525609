import React, { useEffect, useState, useMemo, useCallback } from 'react';
import QuestionCardWrapper from './QuestionCardWrapper';
import QuizTry from './QuizTry';
import { map } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const LessonQuizCard = ({
  quizTitle,
  questionsData,
  setFinishedQuiz,
  setTotalScore,
  retake,
  retakeHandlerToStartCard,
  nextLesson,
  handleOpenQuiz,
  setUseAllTries,
}) => {
  const isMobile = false;
  const [counter, setCounter] = useState(0);
  const [failedAnswers, setFailedAnswers] = useState(0);
  const tries = 3;

  return (
    <div className="lesson-quiz__card regular-card">
      <div className="lesson-quiz__card-inner">
        <div className="lesson-quiz__card-header">
          {!isMobile && (
            <>
              <div className="lesson-quiz__card-title">Lesson quiz</div>
              <div className="lesson-quiz__card-description">{quizTitle}</div>
            </>
          )}

          <QuizTry failedAnswers={failedAnswers} tries={tries} retake={retake} />
        </div>
        <QuestionCardWrapper
          questionsData={questionsData}
          tries={tries}
          counter={counter}
          setCounter={setCounter}
          failedAnswers={failedAnswers}
          setFailedAnswers={setFailedAnswers}
          setFinishedQuiz={setFinishedQuiz}
          setTotalScore={setTotalScore}
          retake={retake}
          retakeHandlerToStartCard={retakeHandlerToStartCard}
          nextLesson={nextLesson}
          handleOpenQuiz={handleOpenQuiz}
          setUseAllTries={setUseAllTries}
        />
      </div>
    </div>
  );
};

export default LessonQuizCard;
