import React, { useMemo } from 'react';
import Svg from '../../../common/SVG';

const Metrics = ({ article }) => {
  if (!article?.metrics) return null;

  return (
    <div className="metrics-list">
      {article?.metrics.map(({ icon, name, quantity }, index) => {
        return (
          <div className="metrics-list__item" key={index}>
            <div className="metrics-list__item__icon">
              <Svg class="metrics-list__item__icon-svg" name={icon} />
            </div>
            <div className="metrics-list__item__name">{name}</div>
            <div className="metrics-list__item__quantity">{quantity}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Metrics;
