import { GET_BOOKMARKS } from '../actions/getBookmarksAction';

const initialState = {
  bookmarks: [],
};

export default function bookmarksReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.payload,
      };
    default:
      return state;
  }
}
