import React, { useEffect, useMemo, useState } from 'react';
import SearchPeople from './SearchPeople';
import SearchLearningMaterials from './SearchLearningMaterials';
import SearchFeedPublication from './SearchFeedPublication';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SubPagesFilterAndContent from '../../common/PageTemplate/SubPagesFilterAndContent';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import { isEmpty } from 'lodash';
import TabLabel from '../../common/Tab/TabLabel';

const Search = () => {
  const token = useSelector(tokenSelector);
  const {
    location: { search },
  } = useHistory();
  const searchValue = useMemo(() => search.replace('?', ''), [search]);

  const [activeTab, setActiveTab] = useState(0);

  const [searchResult, setSearchResult] = useState([]);

  const searchResultTabs = useMemo(
    () => [
      {
        label: 'courses',
        type: 'courses',
        badge: searchResult['courses']?.length,
        content: <SubPagesFilterAndContent articles={searchResult['courses']} position="right" />,
      },
      {
        label: 'articles',
        type: 'articles',
        badge: searchResult['articles']?.length,
        content: <SubPagesFilterAndContent articles={searchResult['articles']} position="right" />,
      },
      {
        label: 'users',
        type: 'users',
        badge: searchResult['users']?.length,
        content: (
          <div className="page-template__content">
            <SearchPeople values={searchResult['users']} />
          </div>
        ),
      },
    ],
    [searchResult, searchValue]
  );

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/search?query=${searchValue}`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setSearchResult(response.data);
      })
      .catch((error) => {
        console.error('Error get search:', error);
      });
  }, [searchValue]);

  const isTabActive = (type) => {
    return searchResult[type]?.length > 0;
  };

  const renderContent = useMemo(() => {
    return (
      !isEmpty(searchResult) &&
      searchResultTabs.map(({ content, badge, type }, key) => {
        return (
          <React.Fragment key={key}>
            {activeTab === type || (isTabActive(type) && content)}
          </React.Fragment>
        );
      })
    );
  }, [searchValue, searchResult, activeTab]);

  return (
    <div className="page-template__container">
      <div className="l-2">
        Search result for <span className="search-page-title__sought">{searchValue}</span>
      </div>
      <div className="page-template__grid">
        <div className="page-template__filter_with-tab">
          <div className="tab-label-list">
            {searchResultTabs.map(({ label, type, badge }) => {
              const isActive = activeTab === type || isTabActive(type);
              return (
                <TabLabel
                  transparent
                  key={label}
                  label={label}
                  onClick={() => {
                    if (!isActive && isTabActive(type)) {
                      setActiveTab(type);
                    }
                  }}
                  active={isActive}
                  disabled={!isTabActive(type)}
                  badgeAmount={badge}
                />
              );
            })}
          </div>
        </div>
        {renderContent}
      </div>
    </div>
  );
};

export default Search;
