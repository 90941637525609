import React from 'react';
import ArticleUser from '../ArticleUser';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import ArticleContentAbsolute from './ArticleContentAbsolute';
import { authorNotSet } from '../ArticleOpenedFooter';
import Svg from '../../../common/SVG';

const ArticleRelated = ({ article, sizeToShow, onClickBookmark, hideBookmark = false }) => {
  const { push } = useHistory();
  const { articleType, author, coverImage, headline, publishedAt, slug } = article;
  const { username, avatar, id: authorId } = author ? author : authorNotSet;
  const onClick = () => {
    article.hasOwnProperty('lessons')
      ? push(`/dashboard/your-learnings/${slug}`)
      : push(`/article/${slug}`);
  };

  return (
    <article className={cx(`article-item__related article-item ${sizeToShow}`)} onClick={onClick}>
      <div className="article-item__wrapper">
        <ArticleContentAbsolute
          article={article}
          type={articleType}
          coverImage={coverImage}
          onClickBookmark={onClickBookmark}
          hideBookmark={hideBookmark}
        />
        <div className="article-item__content-body">
          <div className="article-item__content-body__headers">
            <h3 className="article-item__content-body__title">{headline}</h3>
          </div>
          <div className="article-item__content-footer article-item__content-footer_related">
            <ArticleUser author={username} authorLogo={avatar} authorId={authorId} />
            <div className="article-item__publish-time">
              <Svg name="clock" />
              {dayjs(publishedAt).format('DD.MM.YYYY')}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleRelated;
