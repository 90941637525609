import React, { useCallback } from 'react';
import { instanceFetch } from '../../../helper/instanceFetch';
import { getUserInfoAction } from '../../../store/actions/getUserInfoAction';
import Button from '../../../common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../../../store/selectors';
import { useHistory } from 'react-router-dom';

const StartLessonBtn = ({ course }) => {
  const token = useSelector(tokenSelector);
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { id, status, slug, lessons, nextLesson } = course;
  const courseHasLessons = lessons?.length > 0;

  const ROUTES = {
    START_COURSE: `${process.env.REACT_APP_API_URL}/api/courses/${id}/start`,
  };

  const onClickStartCourse = useCallback(() => {
    instanceFetch
      .post(ROUTES.START_COURSE, {}, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getUserInfoAction(response));
      })
      .catch((error) => {
        console.error('Error post lessons:', error);
      });
    push(`/dashboard/your-learnings/${slug}/${course?.nextLesson?.slug}`);
  }, [course]);

  if (!courseHasLessons) return null;

  if (status === 'passed' && course?.nextLesson === null) {
    return (
      <Button
        className="start-course-btn"
        text="Go to lessons"
        iconRight="arrow-right"
        type="primary"
        size="md"
        onClick={() => {
          push(`/dashboard/your-learnings/${slug}/${course?.lessons[0]?.slug}`);
        }}
      />
    );
  }
  return (
    <Button
      className="start-course-btn"
      text={`${status === 'started' ? 'Continue' : 'Start'} the course`}
      iconRight="arrow-right"
      type="primary"
      size="md"
      onClick={onClickStartCourse}
    />
  );
};

export default StartLessonBtn;
