import React, { useState } from 'react';
import Svg from '../SVG';

const ShowMore = ({ isShowMore, setShowMore, openLabel, closeLabel }) => {
  const toggleShowMore = () => {
    setShowMore(!isShowMore);
  };

  return (
    <button type="button" className="btn show-more-btn" onClick={toggleShowMore}>
      <Svg name={isShowMore ? 'arrow-up' : 'arrow-down'} />
      <div className="show-more-btn__name">{isShowMore ? openLabel : closeLabel}</div>
    </button>
  );
};

export default ShowMore;
