import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InputText from '../../common/Inputs/InputText';
import Select from '../../common/Select/Select';
import Button from '../../common/Button/Button';
import _ from 'lodash';
import { instanceFetch } from '../../helper/instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import { setUserInfoAction } from '../../store/actions/getUserInfoAction';
import { logoutAction } from '../../store/actions/logoutAction';
import { useHistory } from 'react-router-dom';

const onChange = (fn, key) => (event) => {
  if (key === 'departments') {
    return fn((prev) => ({
      ...prev,
      [key]: event,
    }));
  }
  return fn((prev) => ({
    ...prev,
    [key]: event.target.value,
  }));
};

const UserOnboardingFirstStep = ({ handleStep }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const isMobile = false;

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/departments`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setDepartments(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const [userFormData, setUserFormData] = useState({
    username: '',
    jobTitle: '',
    location: '',
    departments: [],
  });

  const optionsDepartments = useMemo(() => {
    if (departments) {
      return _.map(departments, 'name');
    }
    return [];
  }, [departments]);

  const number = useMemo(() => {
    return _.reduce(
      userFormData,
      (r, v) => {
        if (v) {
          if (_.isArray(v) && !v.length) {
            return r;
          }
          return r + 1;
        }
        return r;
      },
      0
    );
  }, [userFormData]);

  const nextStep = useCallback(() => {
    instanceFetch
      .put(
        `${process.env.REACT_APP_API_URL}/api/me`,
        {
          ...userFormData,
          departments: _.map(
            _.filter(departments, (i) => _.includes(userFormData.departments, i.name)),
            'id'
          ),
        },
        {
          authorization: `Bearer ${token}`,
        }
      )
      .then((response) => {
        dispatch(setUserInfoAction(response));
        handleStep((p) => p + 1);
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
        dispatch(logoutAction());
        push('/');
      });
  }, [userFormData]);

  return (
    <>
      <div className="onboarding__title">Welcome! First things first...</div>
      <div className="onboarding__subtitle">Tell a bit about yourself</div>

      <div className="onboarding-form">
        <InputText
          value={userFormData.username}
          labelText="Full name"
          placeholder="Enter your full name"
          onChange={onChange(setUserFormData, 'username')}
        />

        <InputText
          value={userFormData.jobTitle}
          labelText="Job title"
          placeholder="Enter your job title"
          onChange={onChange(setUserFormData, 'jobTitle')}
        />

        <Select
          options={optionsDepartments}
          labelText="Team"
          value={userFormData.departments}
          onChange={onChange(setUserFormData, 'departments')}
          placeholder="Select your department"
        />

        <InputText
          value={userFormData.location}
          labelText="Location"
          placeholder="e.g. Tallin, Estonia"
          onChange={onChange(setUserFormData, 'location')}
        />

        <div className="onboarding-footer">
          <Button
            onClick={nextStep}
            text="Next step"
            type="primary"
            size={isMobile ? 'md' : 'lg'}
            block={isMobile}
            disabled={number !== 4}
          />
          <div className="onboarding-footer__filled">{number} from 4 fields filled</div>
        </div>
      </div>
    </>
  );
};

export default UserOnboardingFirstStep;
