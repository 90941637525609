import React from 'react';
import TabLabel from '../Tab/TabLabel';

const TabsList = ({ tabs, activeTab, setActiveTab }) => {
  return tabs.map(({ label }, key) => (
    <TabLabel
      transparent
      key={label}
      label={label}
      onClick={() => setActiveTab(key)}
      active={activeTab === key}
    />
  ));
};

export default TabsList;
