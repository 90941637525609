import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { courseSelector, tokenSelector } from '../../store/selectors';
import { instanceFetch } from '../../helper/instanceFetch';
import { getImageURL } from '../../helper';
import { isEmpty } from 'lodash';
import LearningCompanyFolderCourses from './LearningCompanyFolderCourses';

const LearningCompanyFolder = () => {
  const token = useSelector(tokenSelector);
  const { slug } = useParams();
  const [learnedCourses, setLearnedCourses] = useState([]);

  const getCoursesCategories = () => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/courses-categories/slug/${slug}`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setLearnedCourses(response);
      })
      .catch((error) => {
        console.error('Error fetching learnedCourses:', error);
      });
  };
  useEffect(() => {
    getCoursesCategories();
  }, []);

  if (isEmpty(learnedCourses)) return null;

  const { name, description, coverImage } = learnedCourses;

  const url = coverImage ? getImageURL(coverImage.url) : '/images/knowledge-base-folder-bg.png';

  return (
    <div className="page-template__container">
      <div className="knowledge-base-folder">
        <div className="knowledge-base-folder-banner" style={{ backgroundImage: `url(${url})` }}>
          <div className="knowledge-base-folder-banner__text">{name}</div>
        </div>
        {description && <div className="knowledge-base-folder__description">{description}</div>}

        <LearningCompanyFolderCourses
          learnedCourses={learnedCourses}
          getCoursesCategories={getCoursesCategories}
        />
      </div>
    </div>
  );
};

export default LearningCompanyFolder;
