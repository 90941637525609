import React, { useEffect } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import './assets/styles/styles.scss';
import Routes from './Routes';
import cx from 'classnames';
import { pageName } from './constants';
import HeaderDesktop from './components/Header/HeaderDesktop';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userSelector } from './store/selectors';
import Sidebar from './components/Sidebar/Sidebar';
import { instanceFetch } from './helper/instanceFetch';
import { setUserInfoAction } from './store/actions/getUserInfoAction';
import { logoutAction } from './store/actions/logoutAction';
import { isMobile } from 'react-device-detect';
import HeaderMobile from './components/Header/HeaderMobile';

const checkRoute = (pathname) => {
  const getPath = pathname.split('/')[1];

  if (pathname === '/') return 'landing-page';

  return pageName.includes(getPath) && `${getPath}-page`;
};

const AppLayout = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const token = useSelector(tokenSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (!token) return;
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/me`, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(setUserInfoAction(response));
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
        dispatch(logoutAction());
        push('/');
      });
  }, [token]);

  const pageClassName = cx(
    'page',
    // authorized ? 'auth' : 'unauth',
    checkRoute(pathname),
    isMobile ? 'mobile' : 'desktop'
  );

  useEffect(() => {
    if (user?.progress?.onboardingPassed === false) {
      push('/onboarding');
    }
  }, []);

  return (
    <div className={pageClassName}>
      {token && !isMobile && <Sidebar />}
      <div className="page__content">
        <div className="page__container">
          {token && (isMobile ? <HeaderMobile /> : <HeaderDesktop />)}
          <Switch>
            <Routes />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
