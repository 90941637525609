import React, { useCallback, useState } from 'react';
import InputText from '../../common/Inputs/InputText';
import FinishReviewEmojiList from './FinishReviewEmojiList';
import Svg from '../../common/SVG';
import LessonQuizFooter from './LessonQuizFooter';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';

const CourseQuizFinishCard = ({
  totalScore,
  handleOpenQuiz,
  retakeHandlerToStartCard,
  nextLesson,
  courseId,
  coursePassed,
  failed,
}) => {
  const token = useSelector(tokenSelector);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleCreatePost = useCallback(() => {
    instanceFetch
      .post(
        `${process.env.REACT_APP_API_URL}/api/courses/${courseId}/feedback`,
        {
          rating: rating,
          feedback: feedback,
        },
        { authorization: `Bearer ${token}` }
      )
      .catch((error) => {
        console.error('Error send feedback:', error);
      });
  }, [rating, feedback, courseId]);

  return (
    <div className="lesson-quiz__card finish-card">
      <div className="lesson-quiz__card-inner">
        <button className="btn lesson-quiz-btn-back" onClick={handleOpenQuiz}>
          <Svg name="close" />
        </button>

        <div className="lesson-quiz__card-title">
          {failed ? 'Quiz is not passed' : 'Quiz is passed'}
        </div>
        <div className="lesson-quiz__card-description">
          {failed
            ? 'You haven’t answered incorrectly for 3 answers so you will need to retake the quiz again to finish this lesson.'
            : `You answered ${totalScore}% of questions correctly`}
        </div>

        {!failed && (
          <>
            <FinishReviewEmojiList rating={rating} setRating={setRating} />
            <div className="lesson-quiz__card-review">
              <InputText
                placeholder="How it could be better?"
                showCount
                maxLength={200}
                onChange={({ target }) => setFeedback(target.value)}
              />
            </div>
          </>
        )}

        <LessonQuizFooter
          retakeHandlerToStartCard={retakeHandlerToStartCard}
          nextLesson={nextLesson}
          handleCreatePost={handleCreatePost}
          coursePassed={coursePassed}
          failed={failed}
        />
      </div>
    </div>
  );
};

export default CourseQuizFinishCard;
