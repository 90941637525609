import React from 'react';
import Dropdown from './Dropdown';
import Tab from '../../common/Tab/Tab';
import _ from 'lodash';

const DropdownLabelPage = ({
  className = 'dropdown-page',
  label,
  array,
  position = 'left',
  selected,
  onClick,
}) => {
  return (
    <Dropdown className={className} label={label} position={position}>
      <div className="dropdown-content-list__nav">
        {array.map((name) => (
          <Tab
            key={name}
            textLeft={name}
            block
            color="whitePurple"
            active={_.includes(selected, name)}
            onClick={() => onClick(name)}
          />
        ))}
      </div>
    </Dropdown>
  );
};

export default DropdownLabelPage;
