import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';
import Svg from '../SVG';
import { useSetBookmarks } from '../../helper/getBookmarks';
import { useGetAllArticlesFeed, useGetArticleBySlug } from '../../helper/getArticle';
import { useLocation } from 'react-router-dom';
import { useGetCourseBySlug } from '../../helper/getCourse';
import { useGetDashboard } from '../../helper/getDashboard';

const Bookmark = ({ article, type, onClickBookmark, showLabelAction = false }) => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  const { id, slug } = article;
  const user = useSelector(userSelector);
  const isOpenedArticle = path === 'article';
  const isCoursePage = pathname.split('/')[2] === 'your-learnings';
  const isFeedPage = path === 'feed';
  const isDashboardPage = path === 'dashboard';
  const learningDevelopment = path === 'learning-and-development-folder';
  const knowledgeBase = path === 'knowledge-base-company-folder';

  const getArticle = useGetArticleBySlug({ slug: slug });
  const getCourse = useGetCourseBySlug({ slug: slug });
  const getAllArticlesFeed = useGetAllArticlesFeed();
  const getDashboard = useGetDashboard();

  const handleSetBookmarks = useSetBookmarks({ type: type, id: id, user: user });

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      handleSetBookmarks(onClickBookmark);
      isOpenedArticle && getArticle();
      isCoursePage && type === 'course' && getCourse();
      isCoursePage && type === 'article' && getArticle();
      isFeedPage && getAllArticlesFeed();
      isDashboardPage && getDashboard();
      learningDevelopment && getCourse();
      knowledgeBase && getArticle();
    },
    [type, user, article]
  );

  return useMemo(() => {
    return (
      <div className="bookmark-container" onClick={onClick}>
        {article.saved ? (
          <>
            <Svg className="bookmark-filled" name="bookmark-filled" />
            {showLabelAction && <span>Saved</span>}
          </>
        ) : (
          <>
            <Svg className="bookmark" name="bookmark" />
            {showLabelAction && <span>Save</span>}
          </>
        )}
      </div>
    );
  }, [article, id, showLabelAction]);
};
export default Bookmark;
