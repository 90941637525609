export const SHOW_CONGRATS = 'SHOW_CONGRATS';
export const RESET_SHOW_CONGRATS = 'RESET_SHOW_CONGRATS';

export const showCongrats = () => ({
  type: SHOW_CONGRATS,
});

export const resetShowCongrats = () => ({
  type: RESET_SHOW_CONGRATS,
});
