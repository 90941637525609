import React, { useCallback, useEffect } from 'react';
import Button from '../../common/Button/Button';

const QuestionCheckAnswer = ({
  checkedAnswerIndex,
  setChecked,
  totalResultAnswer,
  setTotalResultAnswer,
  setAnswerEmpty,
  setFailedAnswers,
  failedAnswers,
  answers,
  setProgressArr,
  progressArr,
  retakeHandlerToStartCard,
  tries,
  singleQuestion,
  multipleQuestion,
  multipleAnswer,
  checkAllAnswersIsTrue,
  setCorrectAnswer,
  setInCorrectAnswer,
}) => {
  useEffect(() => {
    if (totalResultAnswer.length === answers.length) {
      totalResultAnswerFunc();
    }
  }, [totalResultAnswer]);

  const setFailedFunc = () => {
    setChecked(true);
    setFailedAnswers(failedAnswers + 1);
    setProgressArr([...progressArr, false]);
    setInCorrectAnswer(true);
    if (failedAnswers + 1 === tries) {
      retakeHandlerToStartCard();
    }
  };

  const setSuccessFunc = () => {
    setChecked(true);
    setCorrectAnswer(true);
    setProgressArr([...progressArr, true]);
  };

  const totalResultAnswerFunc = useCallback(() => {
    if (singleQuestion) {
      totalResultAnswer.map((result) => {
        if (result['success'] === true) {
          setSuccessFunc();
        } else if (result['error'] === true || result['notSelect'] === true) {
          setFailedFunc();
        }
      });
    }
    if (multipleQuestion) {
      totalResultAnswer.map((result) => {
        if (
          result['success'] === true &&
          result['error'] === false &&
          result['notSelect'] === false
        ) {
          multipleAnswer = multipleAnswer + 1;
        }
        if (
          result['success'] === false &&
          result['error'] === false &&
          result['notSelect'] === true
        ) {
          multipleAnswer = multipleAnswer - 1;
        }
        if (
          result['success'] === false &&
          result['error'] === true &&
          result['notSelect'] === false
        ) {
          multipleAnswer = multipleAnswer - 1;
        }
      });

      if (multipleAnswer === checkAllAnswersIsTrue) {
        setSuccessFunc();
      } else {
        setFailedFunc();
      }
    }
  }, [totalResultAnswer]);

  const checkAnswer = useCallback(() => {
    answers.map((answer, index) => {
      if (answer.correct === true && checkedAnswerIndex.includes(index)) {
        setTotalResultAnswer((totalResultAnswer) => [
          ...totalResultAnswer,
          { success: true, error: false, notSelect: false },
        ]);
      }
      if (answer.correct === true && !checkedAnswerIndex.includes(index)) {
        setTotalResultAnswer((totalResultAnswer) => [
          ...totalResultAnswer,
          { success: false, error: false, notSelect: true },
        ]);
      }
      if (answer.correct === false && checkedAnswerIndex.includes(index)) {
        setTotalResultAnswer((totalResultAnswer) => [
          ...totalResultAnswer,
          { success: false, error: true, notSelect: false },
        ]);
      }
      if (answer.correct === false && !checkedAnswerIndex.includes(index)) {
        setTotalResultAnswer((totalResultAnswer) => [
          ...totalResultAnswer,
          { success: false, error: false, notSelect: false },
        ]);
      }
      setAnswerEmpty(false);
    });
  }, [checkedAnswerIndex]);

  return <Button type="primary" size="md" text="Check answer" onClick={checkAnswer} />;
};
export default QuestionCheckAnswer;
