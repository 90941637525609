import React, { useEffect } from 'react';
import cx from 'classnames';
import { dashboardSelector, userSelector } from '../../store/selectors';
import { useSelector } from 'react-redux';
import DashboardCourse from './DashboardMain/DashboardCourse';
import DashboardRecommended from './DashboardMain/DashboardRecommended';
import DashboardLastSaved from './DashboardMain/DashboardLastSaved';
import DashboardEvents from './DashboardMain/DashboardEvents';
import { Link, useLocation } from 'react-router-dom';
import WelcomeBlock from '../../common/WelcomeBlock/WelcomeBlock';
import DashboardOnboardingDesktop from './DashboardOnboardingDesktop';
import { isEmpty } from 'lodash';
import { useGetDashboard } from '../../helper/getDashboard';

export const sectionHeader = (title, link, disable, onClick) => {
  const onClickHandler = (e) => {
    if (disable) {
      e.preventDefault();
      return;
    }
    onClick && onClick();
  };
  const seeAllClassName = cx('dashboard__section-header-see-all', {
    'dashboard__section-header-see-all_disable': disable,
  });
  return (
    <div className="dashboard__section-header">
      <div className="dashboard__section-header-title">{title}</div>
      {link && (
        <Link to={link} className={seeAllClassName} onClick={onClickHandler}>
          See all
        </Link>
      )}
      {onClick && (
        <div className="dashboard__section-header-see-all" onClick={onClickHandler}>
          See all
        </div>
      )}
    </div>
  );
};

const Dashboard = () => {
  const user = useSelector(userSelector);

  const dashboard = useSelector(dashboardSelector);
  const getDashboard = useGetDashboard();

  useEffect(() => {
    getDashboard();
  }, []);

  if (!dashboard || isEmpty(dashboard)) return null;

  const { articles, courses, bookmarks, events } = dashboard;

  if (user?.progress?.onboardingPassed === false) return null;

  return (
    <div className="dashboard">
      <div className="dashboard__container">
        <div className="l-2">Dashboard</div>

        {!Boolean(user?.progress?.dashboardPassed) && (
          <WelcomeBlock
            type="dashboardPassed"
            title="Welcome to Dashboard"
            description="Here you can find recommended materials, get a quick access to your last saved ones,
          see your learning progress, and get notified on upcoming events"
          />
        )}

        <DashboardOnboardingDesktop />

        {articles && <DashboardRecommended />}

        <div className="dashboard__course-and-events">
          <DashboardCourse courses={courses} />
          <DashboardEvents events={events} />
        </div>

        {bookmarks && <DashboardLastSaved />}
      </div>
    </div>
  );
};

export default Dashboard;
