import React from 'react';
import FeedSidebarTopics from './FeedSidebarTopics';
import FeedSidebarPublishers from './FeedSidebarPublishers';

const RightSidebarFeed = () => {
  return (
    <div className="right-sidebar">
      <div className="right-sidebar__item">
        <div className="right-sidebar__header">
          <span>Topics</span>
        </div>

        <div className="right-sidebar__items">
          <FeedSidebarTopics />
        </div>
      </div>
      <div className="right-sidebar__item">
        <div className="right-sidebar__header">Publishers</div>
        <div className="right-sidebar__items">
          <FeedSidebarPublishers />
        </div>
      </div>
    </div>
  );
};

export default RightSidebarFeed;
