import React, { useState } from 'react';
import Button from '../../common/Button/Button';

const ArticleFollowBtn = () => {
  const isMobile = false;
  const [active, setActive] = useState(false);

  const onClick = () => {
    setActive((active) => !active);
  };
  return (
    <Button
      className="article-follow-btn"
      onClick={onClick}
      type="secondary"
      size={isMobile ? 'xs' : 'md'}
      text={active ? 'Following' : 'Follow'}
      iconLeft={active ? 'checkmark' : 'plus'}
    />
  );
};

export default ArticleFollowBtn;
