import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Tab from '../../common/Tab/Tab';
import { excludeRoute } from '../../constants';
import Svg from '../../common/SVG';
import { Dropdown } from 'antd';
import Avatar from '../../common/Avatar/Avatar';

import _ from 'lodash';
import ButtonIcon from '../../common/Button/ButtonIcon';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';
import { logoutAction } from '../../store/actions/logoutAction';
import SearchData from './SearchData';

export const accountNav = [
  {
    icon: 'user',
    name: 'profile',
    to: '/profile',
  },
  {
    icon: 'draft',
    name: 'drafts',
    to: '/drafts',
  },
];

const HeaderDesktop = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user = useSelector(userSelector);

  const [firstName, lastName] = useMemo(() => {
    if (user) {
      return _.map(_.words(user?.username), _.capitalize);
    }
    return [null, null];
  }, [user]);

  if (excludeRoute.includes(pathname) || !user) {
    return null;
  }
  const handleLogout = () => {
    dispatch(logoutAction());
    push('/');
  };

  const accountMenu = (
    <>
      {/*<div className="dropdown-content-list__nav">*/}
      {/*  {accountNav.map(({ name, icon, to }, index) => (*/}
      {/*    <Link to={to} key={index}>*/}
      {/*      <Tab key={name} textLeft={name} iconLeft={icon} block color="whitePurple" />*/}
      {/*    </Link>*/}
      {/*  ))}*/}
      {/*</div>*/}

      <Tab
        textLeft="Log out"
        iconLeft="logout"
        block
        color="whitePurple"
        onClick={() => handleLogout()}
      />
    </>
  );

  if (!user?.progress?.onboardingPassed) return null;

  return (
    <header className="header desktop">
      <SearchData />
      <div className="header__controls">
        <Link className="btn md outline-white disabled" to="/cms">
          <Svg className="btn__icon-left" name="plus" />
          Add new material
        </Link>

        <div className="header-notification">
          <ButtonIcon disabled icon="bell" size="md" />
        </div>

        <div className="header-account">
          <Dropdown
            overlay={accountMenu}
            trigger={['click']}
            placement="bottomRight"
            className="header-notification-dropdown"
          >
            <Link to="" onClick={(e) => e.preventDefault()}>
              <div className="dropdown-btn__name">
                {firstName} {lastName}
              </div>
              <Avatar size="lg" author={user.username} avatarImg={user.avatar} />
              <Svg className="icon-arrow" name="arrow-down" />
            </Link>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
