import React, { useRef } from 'react';
import DOMPurify from 'dompurify';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';

const markdownRenderer = new MarkdownIt({
  html: true,
  xhtmlOut: true,
  linkify: true,
  typographer: true,
}).use(emoji);

export const handlePaste = (event, pRef, setContent, markdownRenderer, DOMPurify) => {
  event.preventDefault();
  const pastedContent = event.clipboardData.getData('text/plain');
  const markdownHTML = markdownRenderer.render(pastedContent);
  const sanitizedHTML = DOMPurify.sanitize(markdownHTML);
  pRef.current.innerHTML = sanitizedHTML;
  setContent(sanitizedHTML);
};

export const Paragraph = ({ v, index }) => {
  const pRef = useRef(null);
  const [content, setContent] = React.useState(v);
  const onPaste = (event) => handlePaste(event, pRef, setContent, markdownRenderer, DOMPurify);

  const sanitizedValue = DOMPurify.sanitize(markdownRenderer.render(v));
  if (sanitizedValue !== content) {
    setContent(sanitizedValue);
  }

  return (
    <p
      className="s-2"
      id={`${index}-content`}
      data-placeholder="Enter paragraph"
      ref={pRef}
      onPaste={onPaste}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export const Link = ({ v, index }) => {
  const pRef = useRef(null);
  const [content, setContent] = React.useState(v);
  const onPaste = (event) => handlePaste(event, pRef, setContent, markdownRenderer, DOMPurify);

  return (
    <a
      className="article-link-prepare"
      id={`${index}-content`}
      data-placeholder="Enter link"
      ref={pRef}
      onPaste={onPaste}
      href={v}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export const Blockquote = ({ v, index }) => {
  const pRef = useRef(null);
  const [content, setContent] = React.useState(v);
  const onPaste = (event) => handlePaste(event, pRef, setContent, markdownRenderer, DOMPurify);

  return (
    <blockquote
      className="quote-text"
      id={`${index}-content`}
      data-placeholder="Enter blockquote"
      ref={pRef}
      onPaste={onPaste}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

const contentMap = {
  'widgets.paragraph': (v, index) => <Paragraph v={v} index={index} />,

  'h1': (v, index) =>
    React.createElement(
      'h1',
      { 'className': 'l-2', 'id': `${index}-content`, 'data-placeholder': 'Enter headline' },
      v
    ),
  'h2': (v, index) =>
    React.createElement(
      'h2',
      { 'className': 'l-5', 'id': `${index}-content`, 'data-placeholder': 'Enter headline 2' },
      v
    ),
  'h3': (v, index) =>
    React.createElement(
      'h3',
      { 'className': 'l-3', 'id': `${index}-content`, 'data-placeholder': 'Enter headline 3' },
      v
    ),
  'widgets.headline': (v, index) =>
    React.createElement(
      'h1',
      { 'className': 'l-2', 'id': `${index}-content`, 'data-placeholder': 'Enter headline' },
      v
    ),
  'widgets.quote': (v, index) => <Blockquote v={v} index={index} />,
  'widgets.media': (v, index) =>
    React.createElement('img', { src: v, className: 'article-img', id: `${index}-content` }),
  'widgets.link': (v, index) => <Link v={v} index={index} />,
  'widgets.highlight': (v, index) =>
    React.createElement(
      'mark',
      { 'data-placeholder': 'Enter mark text', 'className': 'mark-text', 'id': `${index}-content` },
      v
    ),
  'widgets.video': (v) =>
    React.createElement(
      'div',
      { className: 'article-video' },
      <iframe
        src={v.url}
        width="560"
        height="315"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    ),
  'widgets.video-link': (v) =>
    React.createElement(
      'div',
      { className: 'article-video' },
      <iframe
        src={v}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    ),
  'widgets.embed': (v) => {
    function parseJSONWithFallback(str, fallbackValue) {
      try {
        const parsedObj = JSON.parse(str);
        if (typeof parsedObj === 'object' && parsedObj !== null) {
          return parsedObj;
        }
      } catch (error) {
        console.log(error);
      }
      return fallbackValue;
    }

    const parsedValue = parseJSONWithFallback(v, {});
    const srcValue = parsedValue.url || v;

    return React.createElement(
      'div',
      { className: 'article-video' },
      <iframe
        src={srcValue}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    );
  },
};

export const createArticlesElement = ({ __component, value }, index) => {
  const Element = () => contentMap[__component](value, index);
  return <Element key={index} />;
};
