import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { getImageURL } from '../../helper';

const Avatar = ({ size = 'md', avatarImg, author, className, onClick }) => {
  const avatarClassName = cx('avatar', size, !avatarImg && 'empty', className);
  const [firstName = '', lastName = ''] = _.map(_.words(author), _.capitalize);

  if (!avatarImg || !avatarImg.url)
    return (
      <div className={avatarClassName} onClick={onClick}>
        {firstName[0]}
        {lastName[0]}
      </div>
    );

  return (
    <div
      style={{ backgroundImage: `url(${getImageURL(avatarImg.url)})` }}
      className={avatarClassName}
      onClick={onClick}
    />
  );
};

export default Avatar;
