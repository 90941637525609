import React, { useCallback } from 'react';
import Button from '../Button/Button';
import DashboardBlockInner from '../../pages/Dashboard/DashboardBlockInner';
import { instanceFetch } from '../../helper/instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';
import { setUserInfoAction } from '../../store/actions/getUserInfoAction';

const WelcomeBlock = ({ title, description, type }) => {
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const isMobile = false;

  const closeWelcomeBlock = useCallback(() => {
    instanceFetch
      .post(
        `${process.env.REACT_APP_API_URL}/api/me/progress`,
        {
          [type]: true,
        },
        {
          authorization: `Bearer ${token}`,
        }
      )
      .then((response) => {
        dispatch(setUserInfoAction(response));
      })
      .catch((error) => console.log(error));
  }, [token, type, user]);

  return (
    <DashboardBlockInner className="dashboard-welcome">
      <img className="dashboard-welcome__icon" src="/icons/knowledge.svg" alt="knowledge" />

      <div className="dashboard-welcome__inner">
        <div className="m-6">{title}</div>
        <div className="s-5">{description}</div>
      </div>

      <Button onClick={closeWelcomeBlock} text="Got it" type="primary" size="md" block={isMobile} />
    </DashboardBlockInner>
  );
};

export default WelcomeBlock;
