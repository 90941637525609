import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import useViewBtn from '../../common/PageTemplate/useViewBtn';
import { instanceFetch } from '../../helper/instanceFetch';
import useOnlySort from '../../common/PageTemplate/useOnlySort';
import { nav } from '../../common/PageTemplate/articleTypesFilter';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
import ButtonIcon from '../../common/Button/ButtonIcon';
import KnowledgeBaseCompanyList from '../KnowledgeBase/KnowledgeBaseCompanyList';

const LearningCompany = () => {
  const token = useSelector(tokenSelector);
  const { handleViewListBtn, handleViewGridBtn, activeButton } = useViewBtn();
  const [knowledgeBases, setKnowledgeBases] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/courses-categories`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setKnowledgeBases(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const { selectedSort, onClickSort } = useOnlySort(knowledgeBases, nav);

  return (
    <>
      <div className="page-template__container">
        <h2 className="l-2">Learning and development</h2>
        <div className="page-template__filter sort-and-view-btn">
          <DropdownLabelPage
            label={selectedSort}
            array={nav}
            selected={selectedSort}
            position="left"
            onClick={onClickSort}
          />
          <div className="page-filter-view-btn">
            <ButtonIcon
              icon="view-list-big"
              size="xxs"
              active={activeButton === 'viewListBtn'}
              onClick={handleViewListBtn}
            />
            <ButtonIcon
              icon="view-grid"
              size="xxs"
              active={activeButton === 'viewGridBtn'}
              onClick={handleViewGridBtn}
            />
          </div>
        </div>
        <KnowledgeBaseCompanyList
          list={knowledgeBases}
          viewListBtn={activeButton === 'viewListBtn'}
          viewGridBtn={activeButton === 'viewGridBtn'}
          url="/learning-and-development-folder"
        />
      </div>
    </>
  );
};

export default LearningCompany;
