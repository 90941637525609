import React from 'react';
import cx from 'classnames';
import Badge from '../Badge/Badge';

const TabLabel = ({ badgeAmount, active, onClick, disabled, label, transparent, children }) => {
  const tabClassName = cx(
    'tab-label',
    active && 'active',
    disabled && 'disabled',
    transparent && 'transparent'
  );

  return (
    <div className={tabClassName} onClick={onClick}>
      <span className="tab-label__text">{label}</span>
      {badgeAmount !== undefined && <Badge label={badgeAmount} size="xs" isIcon={false} />}
      {children}
    </div>
  );
};

export default TabLabel;
