import React, { useState, useCallback, useMemo } from 'react';
import Svg from '../../common/SVG';
import cx from 'classnames';
import SidebarAuthNav from './SidebarAuthNav';
import { Link } from 'react-router-dom';
import SidebarKnowledgeBase from './SidebarKnowledgeBase';
import SidebarLearningDevelopment from './SidebarLearningDevelopment';

const SidebarAuthDesktop = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isCollapsed, setIsCollapsed] = useState(windowWidth < 1440);

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
    setIsCollapsed(windowWidth < 1440);
  });

  const handleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const sidebarCollapsed = useMemo(() => {
    if (isCollapsed) {
      return (
        <div className={cx({ 'sidebar-collapsed': isCollapsed })}>
          <Link className="sidebar-logo" to="/dashboard">
            <img className="sidebar-logo__img" src="/images/logoS.svg" alt="logo" />
          </Link>
          <div className="collapse-btn" onClick={handleClick}>
            <Svg name="arrow-right" />
          </div>
        </div>
      );
    }
  }, [isCollapsed]);

  return (
    <aside className={cx('sidebar auth', { 'sidebar-collapse': isCollapsed })}>
      <div className="sidebar__box">
        <div className="sidebar-collapse">
          <Link className="sidebar-logo" to="/dashboard">
            <img className="sidebar-logo__img" src="/images/circle.io-logo.svg" alt="logo" />
          </Link>
          <SidebarAuthNav />

          <div className="sidebar-sub-nav-list">
            <span className="sidebar-sub-nav-title">Knowledge base</span>
            <SidebarKnowledgeBase />
            <SidebarLearningDevelopment />
          </div>
        </div>
        <div className="collapse-btn sidebar-collapse-btn" onClick={handleClick}>
          <Svg name="arrow-left" />
        </div>
        {sidebarCollapsed}
      </div>
    </aside>
  );
};

export default SidebarAuthDesktop;
