import React from 'react';
import { Input } from 'antd';
import Svg from '../SVG';
import OutsideClickWrapper from '../OutsideClickWrapper/OutsideClickWrapper';
import Popups from '../Popups/Popups';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';

const SearchInput = (props) => {
  const token = useSelector(tokenSelector);
  const {
    placeholder,
    id,
    children,
    value,
    showChildren,
    setSearch,
    close,
    goToToSearch,
    setSearchResult,
    setShowResult,
  } = props;

  const handleInput = ({ target: { value } }) => {
    setSearch(value);
    value.length >= 3 &&
      instanceFetch
        .get(`${process.env.REACT_APP_API_URL}/api/search?query=${value}`, {
          authorization: `Bearer ${token}`,
        })
        .then((response) => {
          setSearchResult(response.data);
          setShowResult(true);
        })
        .catch((error) => {
          console.error('Error get search:', error);
        });
    value.length < 3 && setShowResult(false);
  };

  return (
    <OutsideClickWrapper close={close}>
      <div className="search">
        <div className="search__inner">
          <Input
            onKeyDown={goToToSearch}
            onChange={(e) => handleInput(e)}
            className="search__input"
            placeholder={placeholder}
            id={id}
            value={value}
          />

          <label htmlFor={id} className="search__label">
            <Svg name="search" />
          </label>
        </div>

        {showChildren && <Popups>{children}</Popups>}
      </div>
    </OutsideClickWrapper>
  );
};

export default SearchInput;
