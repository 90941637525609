import React, { useEffect } from 'react';

const Login = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(`${process.env.REACT_APP_API_URL}/connect/google`);
    }, 3000);
  }, []);

  return <div> you redirect login to google</div>;
};

export default Login;
