import React from 'react';

const Progressbar = ({ progress }) => {
  const { passed, total, remaining } = progress;
  const progressPercent = (100 * (passed / total)).toFixed();
  if (total === 0) {
    return <span>No lessons added yet</span>;
  }
  return (
    <div className="progressbar">
      <div className="progressbar__text">{`${progressPercent}% progress`}</div>
      <div className="progressbar__line-bg">
        <div className="progressbar__line" style={{ width: `${progressPercent}%` }} />
      </div>
    </div>
  );
};

export default Progressbar;
