import { gql } from '@apollo/client';
import { LESSON, LIKE, USER, USER_ALL_PARAMS, INTERESTS } from './constQuery';

export const createLikeMutationGql = gql`
  ${LIKE}
  ${USER}
  mutation createLike($input: createLikeInput) {
    createLike(input: $input) {
      like {
        ...like
      }
    }
  }
`;

export const deleteLikeMutationGql = gql`
  ${LIKE}
  ${USER}
  mutation deleteLike($input: deleteLikeInput) {
    deleteLike(input: $input) {
      like {
        ...like
      }
    }
  }
`;

export const createSavedMutationGql = gql`
  mutation createSaved($input: createSavedInput) {
    createSaved(input: $input) {
      saved {
        id
        article {
          id
        }
      }
    }
  }
`;

export const deleteSavedMutationGql = gql`
  mutation deleteSaved($input: deleteSavedInput) {
    deleteSaved(input: $input) {
      saved {
        id
        article {
          id
        }
      }
    }
  }
`;

export const updateUserMutationGql = gql`
  ${USER_ALL_PARAMS}
  ${INTERESTS}
  mutation updateUser($input: updateUserInput) {
    updateUser(input: $input) {
      user {
        ...user
      }
    }
  }
`;

export const createCommentMutationGql = gql`
  ${USER}
  mutation createComment($input: createCommentInput) {
    createComment(input: $input) {
      comment {
        id
        comment
        user {
          ...user
        }
      }
    }
  }
`;

export const updateLessonMutationGql = gql`
  ${LESSON}
  ${USER}
  mutation updateLesson($input: updateLessonInput) {
    updateLesson(input: $input) {
      lesson {
        ...lesson
      }
    }
  }
`;

export const createArticleMutationGql = gql`
  mutation createArticle($input: createArticleInput) {
    createArticle(input: $input) {
      article {
        id
        slug
        contents
      }
    }
  }
`;

export const updateArticleMutationGql = gql`
  mutation updateArticle($input: updateArticleInput) {
    updateArticle(input: $input) {
      article {
        id
        slug
      }
    }
  }
`;

export const uploadMutationGql = gql`
  mutation upload($file: Upload!) {
    upload(file: $file) {
      id
      url
    }
  }
`;

export const createFeedbackMutationGql = gql`
  mutation createFeedback($input: createFeedbackInput) {
    createFeedback(input: $input) {
      feedback {
        rating
        feedback
        user {
          id
        }
        course {
          id
        }
      }
    }
  }
`;
