import { useCallback } from 'react';
import { instanceFetch } from './instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';
import { setCommentsAction } from '../store/actions/getCommentsAction';

export const useSetComments = ({ type, id }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    ADD_COMMENT: `${process.env.REACT_APP_API_URL}/api/comment/${type}/${id}`,
  };

  return useCallback((newCommentAddInput, setAddCommentInput, onClickComment) => {
    instanceFetch
      .post(
        ROUTES.ADD_COMMENT,
        {
          type: type,
          id: id,
          comment: newCommentAddInput,
        },
        { authorization: `Bearer ${token}` }
      )
      .then((response) => {
        dispatch(setCommentsAction(response));
        setAddCommentInput('');
      })
      .then(() => {
        onClickComment && onClickComment();
      })
      .catch((error) => {
        console.error('Error post comment:', error);
      });
  }, []);
};
