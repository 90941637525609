import React, { useCallback, useState } from 'react';
import InputText from '../../common/Inputs/InputText';
import FinishReviewEmojiList from './FinishReviewEmojiList';
import Svg from '../../common/SVG';
import LessonQuizFooter from './LessonQuizFooter';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';

const LessonQuizFinishCard = ({
  totalScore,
  handleOpenQuiz,
  retakeHandlerToStartCard,
  nextLesson,
  coursePassed,
  failed,
  isLessonQuiz,
}) => {
  return (
    <div className="lesson-quiz__card finish-card finish-card__failed">
      <div className="lesson-quiz__card-inner">
        <button className="btn lesson-quiz-btn-back" onClick={handleOpenQuiz}>
          <Svg name="close" />
        </button>
        <div className="lesson-quiz__card-title">
          {failed ? 'Quiz is not passed' : 'Quiz is passed'}
        </div>
        <div className="lesson-quiz__card-description">
          {failed
            ? 'You haven’t answered incorrectly for 3 answers so you will need to retake the quiz again to finish this lesson.'
            : `You answered ${totalScore}% of questions correctly`}
        </div>

        <LessonQuizFooter
          backToLessonHandler={handleOpenQuiz}
          retakeHandlerToStartCard={retakeHandlerToStartCard}
          nextLesson={nextLesson}
          coursePassed={coursePassed}
          failed={failed}
          isLessonQuiz={isLessonQuiz}
        />
      </div>
    </div>
  );
};

export default LessonQuizFinishCard;
