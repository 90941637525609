import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  articlesFeedSelector,
  articlesFollowingFeedSelector,
  userSelector,
} from '../../store/selectors';
import { isEmpty } from 'lodash';
import RightSidebarFeed from './RightSidebarFeed';
import FeedContent from './FeedContent';
import { useGetAllArticlesFeed, useGetFollowingArticlesFeed } from '../../helper/getArticle';
import WelcomeBlock from '../../common/WelcomeBlock/WelcomeBlock';

const Feed = () => {
  const user = useSelector(userSelector);
  const feedArticles = useSelector(articlesFeedSelector);
  const feedArticlesFollowing = useSelector(articlesFollowingFeedSelector);

  const getAllArticlesFeed = useGetAllArticlesFeed();
  // const getArticlesFollowingFeed = useGetFollowingArticlesFeed();

  useEffect(() => {
    getAllArticlesFeed();
    // getArticlesFollowingFeed();
  }, []);

  const feedContent = useMemo(() => {
    if (isEmpty(feedArticles)) return null;
    return (
      <FeedContent feedArticles={feedArticles} feedArticlesFollowing={feedArticlesFollowing} />
    );
  }, [feedArticles, feedArticlesFollowing]);

  if (user?.progress?.onboardingPassed === false) return null;

  return (
    <div className="page-template__container feed__page">
      <div className="feed__page-wrapper">
        <div className="feed__page-content">
          <div className="l-2">Feed</div>

          {!Boolean(user?.progress?.feedPassed) && (
            <WelcomeBlock
              type="feedPassed"
              title="Welcome to Feed"
              description="Discover publications your teammates and friends share"
            />
          )}

          {feedContent}
        </div>
        <RightSidebarFeed />
      </div>
    </div>
  );
};

export default Feed;
