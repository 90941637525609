import React, { useCallback, useEffect, useState } from 'react';
import Tab from '../../common/Tab/Tab';
import { useHistory } from 'react-router-dom';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import learningDevelopment from '../../pages/LearningDevelopment/LearningDevelopment';

const learningDevelopmentRote = 'learning-and-development';
const learningDevelopmentFolderRote = 'learning-and-development-folder';
const SidebarLearningDevelopment = () => {
  const token = useSelector(tokenSelector);
  const [isOpenLearning, setIsOpenLearning] = useState(false);
  const [coursesCategories, setCoursesCategories] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/courses-categories`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setCoursesCategories(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const {
    push,
    location: { pathname },
  } = useHistory();

  const getPath = pathname.split('/')[1];
  const getPathId = pathname.split('/')[2];

  const handleFolder = (slug) => {
    if (pathname === `/${learningDevelopmentFolderRote}/${slug}`) return;
    push(`/${learningDevelopmentFolderRote}/${slug}`);
  };

  const handleCompany = (link) => {
    push(link);
  };

  // console.log('pathname', pathname)
  const handleOpenCompany = () => {
    setIsOpenLearning((isOpenLearning) => !isOpenLearning);
    if (pathname === `/${learningDevelopmentRote}`) return;
    handleCompany(`/${learningDevelopmentRote}`);
  };

  const sortedAlphabetically = useCallback(
    (coursesCategories) => {
      if (!coursesCategories) return;
      return Object.values(coursesCategories).sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    [coursesCategories]
  );

  if (!coursesCategories) return null;

  const subNav = sortedAlphabetically(coursesCategories).map(({ name, id, slug }) => {
    return (
      <Tab
        onClick={() => handleFolder(slug)}
        key={id}
        typeLeft="item"
        textLeft={name}
        active={getPath === learningDevelopmentFolderRote && getPathId === slug}
        color="whitePurple"
      />
    );
  });
  return (
    <div className="sidebar-sub-nav">
      <div>
        <Tab
          typeLeft="item"
          textLeft="Learning"
          iconLeft="learning"
          typeRight="arrow"
          color="whitePurple"
          isOpen={isOpenLearning || getPath === learningDevelopmentFolderRote}
          active={getPath === learningDevelopmentRote}
          onClick={() => handleOpenCompany()}
        />
        {(isOpenLearning || getPath === learningDevelopmentFolderRote) && subNav}
      </div>
    </div>
  );
};

export default SidebarLearningDevelopment;
