export const GET_ALL_ARTICLES_FEED = 'GET_ALL_ARTICLES_FEED';
export const SET_ALL_ARTICLES_FEED = 'SET_ALL_ARTICLES_FEED';
export const GET_FOLLOWING_ARTICLES_FEED = 'GET_FOLLOWING_ARTICLES_FEED';
export const SET_FOLLOWING_ARTICLES_FEED = 'SET_FOLLOWING_ARTICLES_FEED';

// export const setArticlesFeed = (res) => {
//   return {
//     type: SET_ALL_ARTICLES_FEED,
//     payload: res,
//   };
// };

export const getArticlesFeed = (articles) => {
  return {
    type: GET_ALL_ARTICLES_FEED,
    payload: articles,
  };
};

// export const getArticlesFollowingFeed = (articles) => {
//   return {
//     type: SET_FOLLOWING_ARTICLES_FEED,
//     payload: articles,
//   };
// };
//
// export const setArticlesFollowingFeed = (res) => {
//   return {
//     type: SET_FOLLOWING_ARTICLES_FEED,
//     payload: res,
//   };
// }
