import React from 'react';
import Button from '../../common/Button/Button';

const LoginWithGoogle = () => {
  return (
    <a href={`${process.env.REACT_APP_API_URL}/api/connect/google`}>
      <Button
        text="Log in with Google"
        type="outline-purple"
        size="lg"
        className="button__google"
        imageLeft="/images/google-logo.svg"
      />
    </a>
  );
};

export default LoginWithGoogle;
