import React from 'react';
import ArticleUser from '../ArticleUser';
import cx from 'classnames';
import { getImageURL } from '../../../helper';
import Bookmark from '../../../common/Bookmark/Bookmark';
import TagLabel from '../../../common/Tag/TagLabel';
import { useHistory } from 'react-router-dom';
import Likes from '../../../common/Likes/Likes';
import Comment from '../../../common/Comment/Comment';

const ArticleClassic = ({ article, sizeToShow }) => {
  // console.log(article, 'ArticleClassic')
  const { push } = useHistory();

  const { articleType, author, coverImage, description, headline, id, slug } = article;

  const { username, avatar, id: authorId } = author;

  const type = article.hasOwnProperty('lessons') ? 'course' : 'article';
  const onClick = () => {
    push(`/article/${slug}`);
  };

  return (
    <article className={cx('article-item__classic article-item', sizeToShow)} onClick={onClick}>
      <div className="article-item__wrapper">
        <div className="article-item__header">
          <ArticleUser author={username} authorLogo={avatar} authorId={authorId} />
          {/*<Button type="secondary" size="xs" text="Following" iconLeft="plus" disabled />*/}
        </div>

        <div className="article-item__content">
          <div
            className={cx('article-item__content-image', {
              'article-item__content-image--no-cover': !coverImage,
            })}
          >
            {coverImage?.url && <img src={getImageURL(coverImage.url)} alt="" />}
          </div>
          <div className="article-item__content-absolute">
            <div className="article-item__content-bookmark">
              <Bookmark type={type} article={article} />
            </div>
            <div className="article-item__content-type">
              <TagLabel text={articleType?.name} icon="image" />
            </div>
          </div>
        </div>
        <div className="article-item__content-body">
          <div className="article-item__content-body__headers">
            <h3 className="article-item__content-body__title" onClick={onClick}>
              {headline}
            </h3>
            <div className="article-item__content-body__description" onClick={onClick}>
              {description}
            </div>
          </div>
          <div className="article-item__content-footer">
            <div className="article-item__content-footer__peoples">
              <Comment type={type} article={article} showLabelAction />
            </div>
            <div className="article-item__content-footer__claps">
              <Likes type={type} article={article} showLabelAction />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleClassic;
