import React from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper/core';
import cx from 'classnames';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const SwipeWrapper = ({
  children,
  spaceBetween = 0,
  className,
  navigation = true,
  centeredSlides = false,
  freeMode = true,
}) => {
  const isMobile = false;
  SwiperCore.use([Navigation, Mousewheel]);

  const swiperClass = cx('swiper-container-block', className);
  return (
    <Swiper
      onInit={(swiper) => {
        setTimeout(
          () => {
            swiper.update();
          },
          isMobile ? 5000 : 500
        );
      }}
      observeParents={true}
      observer={true}
      slidesPerView="auto"
      navigation={isMobile ? false : navigation}
      autoHeight={true}
      spaceBetween={spaceBetween}
      freeMode={freeMode}
      centeredSlides={centeredSlides}
      // mousewheel={true}
      className={swiperClass}
    >
      {children}
    </Swiper>
  );
};
export default SwipeWrapper;
