import { SET_TOKEN } from '../actions/setTokenAction';

const initialState = {
  token: '',
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}
