import { GET_USER_INFO, SET_USER_INFO } from '../actions/getUserInfoAction';

const initialState = {
  user: {},
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
