export const GET_ARTICLES_DASHBOARD = 'GET_ARTICLES_DASHBOARD';
export const GET_BOOKMARKS_DASHBOARD = 'GET_BOOKMARKS_DASHBOARD';
export const GET_EVENTS_DASHBOARD = 'GET_EVENTS_DASHBOARD';
export const GET_COURSES_DASHBOARD = 'GET_COURSES_DASHBOARD';
export const GET_ERROR_DASHBOARD = 'GET_ERROR_DASHBOARD';
export const GET_ALL_DASHBOARD = 'GET_ALL_DASHBOARD';

const initialState = {
  articles: [],
  bookmarks: [],
  courses: [],
  events: [],
  dashboard: [],
  error: null,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLES_DASHBOARD:
      return {
        ...state,
        articles: action.payload,
      };
    case GET_BOOKMARKS_DASHBOARD:
      return {
        ...state,
        bookmarks: action.payload,
      };
    case GET_EVENTS_DASHBOARD:
      return {
        ...state,
        events: action.payload,
      };
    case GET_COURSES_DASHBOARD:
      return {
        ...state,
        courses: action.payload,
      };
    case GET_ERROR_DASHBOARD:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    default:
      return state;
  }
}
