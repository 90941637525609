import { instanceFetch } from './instanceFetch';

export const signIn = async (callback, location) => {
  try {
    const res = await instanceFetch.get(
      `${process.env.REACT_APP_API_URL}/api/auth/google/callback${location}`
    );
    callback(res.user, res.jwt);
  } catch (error) {
    console.log(error, 'error');
  }
};
