import {
  GET_ARTICLES_DASHBOARD,
  GET_BOOKMARKS_DASHBOARD,
  GET_COURSES_DASHBOARD,
  GET_EVENTS_DASHBOARD,
  GET_ALL_DASHBOARD,
} from '../reducers/dashboardReducer';

export const getArticlesDashboard = (res) => {
  return {
    type: GET_ARTICLES_DASHBOARD,
    payload: res.articles,
  };
};

export const getBookmarksDashboard = (res) => {
  return {
    type: GET_BOOKMARKS_DASHBOARD,
    payload: res.bookmarks,
  };
};

export const getEventsDashboard = (res) => {
  return {
    type: GET_EVENTS_DASHBOARD,
    payload: res.events,
  };
};

export const getCoursesDashboard = (res) => {
  return {
    type: GET_COURSES_DASHBOARD,
    payload: res.courses,
  };
};

export const getAllDashboard = (res) => {
  return {
    type: GET_ALL_DASHBOARD,
    payload: res,
  };
};
