import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { Modal } from 'antd';
import Svg from '../../common/SVG';
import cx from 'classnames';

const ModalWrapper = ({
  className,
  modalWidth,
  children,
  isModalVisible,
  handleClick,
  showCloseIcon = true,
}) => {
  const modalClassName = cx(className, 'desktop');

  return (
    <Modal
      className={modalClassName}
      visible={isModalVisible}
      footer={null}
      onCancel={handleClick}
      width={modalWidth}
      closable={showCloseIcon}
      closeIcon={<Svg name="close" />}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
