import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';
import { useCallback } from 'react';
import { instanceFetch } from './instanceFetch';
import { getAllDashboard } from '../store/actions/dashboardAction';

export const useGetDashboard = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  return useCallback(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/dashboard`, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getAllDashboard(response));
      })
      .catch((error) => {
        console.error('Error fetching dashboard:', error);
      });
  }, []);
};
