import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { articleSelector } from '../../../store/selectors';
import { createArticlesElement } from '../../../helper';
import Metrics from './Metrics';
import ArticleDefaultFooter from './ArticleDefaultFooter';
import SidebarActions from './SidebarActions';
import { useGetArticleBySlug } from '../../../helper/getArticle';
import { isEmpty } from 'lodash';
import { getArticleAction } from '../../../store/actions/getArticleAction';

const ArticleOpened = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const article = useSelector(articleSelector);
  const getArticle = useGetArticleBySlug({ slug: slug });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articleData = await getArticle();
        dispatch(getArticleAction(articleData));
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchData();
  }, [getArticle]);

  if (isEmpty(article) || !article) return null;

  return (
    <div className="page__material">
      <div className="article-opened">
        <div className="article-opened__wrapper">
          <div className="article-opened-content">
            <h1 className="l-1">{article?.headline}</h1>
            <Metrics article={article} />
            {article?.content.map((item, index) => createArticlesElement(item, index))}
          </div>
          <ArticleDefaultFooter article={article} type="article" />
        </div>
        <div className="article-opened-sidebar">
          <SidebarActions article={article} type="article" />
        </div>
      </div>
    </div>
  );
};

export default ArticleOpened;
