import React from 'react';
import cx from 'classnames';
import Button from '../Button/Button';
import { useMediaQuery } from '../../pages/Dashboard/ResizeArticleInPage';
import { getImageURL } from '../../helper';
import { isEmpty } from 'lodash';
import Avatar from '../Avatar/Avatar';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';
import useUserFollow from '../useUserFollow';

const SearchCard = ({ item, className }) => {
  const user = useSelector(userSelector);
  let searchCardSize = useMediaQuery('(min-width: 1920px)');

  const cardClassName = cx('search-card', className, searchCardSize ? 'lg' : 'md');
  const { coverImage, avatar, username: name, jobTitle: position } = item;
  const bg = coverImage ? coverImage.url : null;
  const av = avatar ? avatar.url : '';

  const { onClickFollow, onClickUnFollow } = useUserFollow();

  const isFollowing =
    user?.followings && user?.followings.some((following) => following.id === item.id);

  return (
    <div className={cardClassName}>
      <div
        style={{
          backgroundImage: isEmpty(bg) ? `url(/images/bg-cover.png)` : `url(${getImageURL(bg)})`,
        }}
        className="search-card__bg"
      />

      <div className="search-card__content">
        <div className="search-card-avatar">
          {isEmpty(avatar) ? (
            <Avatar author={name} className="search-card-avatar__img" />
          ) : (
            <div
              style={{ backgroundImage: `url(${getImageURL(av)})` }}
              className="search-card-avatar__img"
            />
          )}
        </div>

        <div className="search-card__inner">
          <div className="search-card__name">{name}</div>
          <div className="search-card__position">{position}</div>

          <Button
            iconLeft={isFollowing ? 'checkmark' : 'plus'}
            type="secondary"
            text={isFollowing ? 'Following' : 'Follow'}
            onClick={(e) => {
              e.stopPropagation();
              isFollowing ? onClickUnFollow(item.id) : onClickFollow(item.id);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
