export const EMAIL_RGX = /^\S+@\S+\.\S+$/;

export const excludeRoute = ['/', '/login', '/сheck-email', '/oauth'];

export const pageName = ['feed', 'dashboard', 'profile', 'onboarding', 'material', 'course'];

export const renderSideBar = ['/', '/login', '/сheck-email', '/available-organizations'];

export const USER_INFO = [
  {
    icon: 'mail',
    label: 'Email',
    value: 'email',
  },
  {
    icon: 'home',
    label: 'Team',
    value: 'departments',
  },
  {
    icon: 'location',
    label: 'Location',
    value: 'location',
  },
  {
    icon: 'birthday',
    label: 'Birthday',
    value: 'birthDate',
  },
  {
    icon: 'phone',
    label: 'Phone',
    value: 'phone',
  },
];
