import React from 'react';
import SearchCard from '../../common/SearchCard/SearchCard';

const SearchPeople = ({ values }) => {
  console.log(values, 'values');
  if (!values.length) return <div>Пусто</div>;

  return (
    <div className="cards-grid">
      {values.map((item) => (
        <SearchCard item={item} key={item.id} />
      ))}
    </div>
  );
};

export default SearchPeople;
