import React from 'react';
import Button from '../../common/Button/Button';
import { useHistory } from 'react-router-dom';

const CourseModal = ({
  children,
  nextClick,
  prevClick,
  text,
  isQuizAvailable,
  handleOpenQuiz,
  course,
}) => {
  return (
    <>
      {children}
      <div className="course-lessons__footer">
        {isQuizAvailable && course?.status !== 'passed' ? (
          <>
            <Button
              type="outline-purple"
              size="md"
              text="Back"
              iconLeft="arrow-left"
              onClick={prevClick}
            />
            <Button
              type="primary"
              size="md"
              text="Take a quiz"
              iconRight="arrow-right"
              onClick={handleOpenQuiz}
            />
          </>
        ) : (
          <>
            <Button
              type="outline-purple"
              size="md"
              text="Back"
              iconLeft="arrow-left"
              onClick={prevClick}
            />
            <Button
              type="primary"
              size="md"
              text={text}
              iconRight="arrow-right"
              onClick={nextClick}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CourseModal;
