import React, { useMemo } from 'react';
import _ from 'lodash';
import { nav } from './articleTypesFilter';
import ArticleRelated from '../../components/Articles/types/ArticleRelated';
import PageTemplateArticleFormatsFilter from './PageTemplateArticleFormatsFilter';
import useFilterAndSort from './useFilterAndSort';

const SubPagesFilterAndContent = ({ articles, getBookmarks, position }) => {
  const {
    onSelect,
    selected,
    showOnlyAvailableTypes,
    labelFormats,
    selectedSort,
    onClickSort,
    getFilteredAndSortedArticles,
  } = useFilterAndSort(articles, nav);

  const renderArticles = useMemo(() => {
    return getFilteredAndSortedArticles.map((article, index) => (
      <ArticleRelated
        key={article.id}
        article={article}
        sizeToShow="md"
        onClickBookmark={getBookmarks}
      />
    ));
  }, [getFilteredAndSortedArticles]);

  return (
    <>
      <PageTemplateArticleFormatsFilter
        labelFormats={_.upperFirst(labelFormats)}
        arrayFormats={showOnlyAvailableTypes}
        selectedFormats={selected}
        onClickFormats={(name) => onSelect(name)}
        labelSort={selectedSort}
        arraySort={nav}
        selectedSort={selectedSort}
        onClickSort={onClickSort}
        position={position}
      />
      <div className="page-template__content">
        <div className="page-template__articles">{renderArticles}</div>
      </div>
    </>
  );
};

export default SubPagesFilterAndContent;
