import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { sectionHeader } from '../Dashboard';
import ArticleRelated from '../../../components/Articles/types/ArticleRelated';
import { useSelector } from 'react-redux';
import { dashboardSelector } from '../../../store/selectors';

const DashboardLastSaved = () => {
  const dashboard = useSelector(dashboardSelector);
  const { bookmarks } = dashboard;

  const isEmptyBookmarks = isEmpty(bookmarks);

  const renderBookmarks = useMemo(() => {
    return bookmarks.map((article) => (
      <ArticleRelated key={article.id} article={article} sizeToShow="md" />
    ));
  }, [bookmarks]);

  const disableSeeAll = isEmptyBookmarks || bookmarks.length < 2;

  if (isEmptyBookmarks) return null;

  return (
    <div className="dashboard__bookmarks">
      {sectionHeader('Last saved', '/dashboard/saved', disableSeeAll)}
      <div className="dashboard__bookmarks-content">{renderBookmarks}</div>
    </div>
  );
};

export default DashboardLastSaved;
