import React, { useEffect, useMemo, useState } from 'react';
import Timeline from '../Timeline/Timeline';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';

const SidebarCourse = () => {
  const token = useSelector(tokenSelector);
  const { pathname } = useLocation();

  const { idLesson, idCourse } = useMemo(() => {
    const arr = _.split(pathname, '/');
    const { length } = arr;
    const idLesson = arr[length - 1];
    const idCourse = arr[length - 2];
    return {
      idLesson,
      idCourse,
    };
  }, [pathname]);

  const [course, setCourse] = useState();

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/courses/slug/${idCourse}`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setCourse(response);
      })
      .catch((error) => console.log(error));
  }, [pathname]);

  const timeLineArray = useMemo(() => {
    if (course) {
      return _.map(course?.lessons, ({ status, headline, slug }) => {
        const type =
          status === 'passed' && slug === idLesson
            ? 'progress'
            : status === 'passed'
            ? 'done'
            : slug === idLesson
            ? 'progress'
            : 'waiting';
        return {
          type,
          title: headline,
        };
      });
    }
    return [];
  }, [course]);

  return (
    <aside className="sidebar-course">
      <Link className="sidebar-logo" to="/">
        <img className="sidebar-logo__img" src="/images/logo.png" alt="logo" />
      </Link>
      <Timeline timeLineArray={timeLineArray} vertical />
    </aside>
  );
};

export default SidebarCourse;
