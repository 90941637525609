import React, { useCallback } from 'react';
import Avatar from '../../common/Avatar/Avatar';
import ArticleFollowBtn from './ArticleFollowBtn';
import ArticleOpenedActions from './opened/ArticleOpenedActions';
import TagLabel from '../../common/Tag/TagLabel';

export const authorNotSet = {
  username: 'author not specified',
  avatar: null,
};
const ArticleOpenedFooter = ({ article, type }) => {
  const { author, topics } = article;

  const renderArticleOpenedFooter = useCallback(
    (avatar, username) => {
      return (
        <div className="article-opened-footer">
          <div className="article-opened-footer__user">
            <Avatar author={username} avatarImg={avatar} />
            <div className="user-info">
              <span className="user-info__title">PUBLISHED BY</span>
              <span className="user-info__name">{username}</span>
            </div>
            <ArticleFollowBtn author={author} />
          </div>
          <div className="article-opened-footer__topics">
            {topics.map((value, index) => (
              <div className="article-opened-footer__topics-item" key={index}>
                <TagLabel text={value.name} className="article-topics" size="md" />
              </div>
            ))}
          </div>
          <div className="article-item__actions">
            <ArticleOpenedActions article={article} showLabelAction type={type} />
          </div>
        </div>
      );
    },
    [article]
  );

  if (!author) return renderArticleOpenedFooter(authorNotSet.avatar, authorNotSet.username);

  return renderArticleOpenedFooter(author.avatar, author.username);
};

export default ArticleOpenedFooter;
