import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { instanceFetch } from '../../../helper/instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { courseSelector, tokenSelector, userSelector } from '../../../store/selectors';
import { setUserInfoAction } from '../../../store/actions/getUserInfoAction';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import { createArticlesElement } from '../../../helper';
import CourseModal from '../../Modals/CourseModal';
import LessonQuiz from '../../../pages/LessonQuiz/LessonQuiz';

const Lesson = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const token = useSelector(tokenSelector);
  const course = useSelector(courseSelector);

  const { pathname } = useLocation();

  const { slug } = useParams();

  const [openQuiz, setOpenQuiz] = useState(false);
  const handleOpenQuiz = () => {
    setOpenQuiz((openQuiz) => !openQuiz);
  };

  if (isEmpty(course)) {
    push('/dashboard/your-learnings');
  }

  const [lesson, setLesson] = useState(null);
  const getLesson = (slug) => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/lessons/slug/${slug}`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setLesson(response);
      })
      .catch((error) => {
        console.error('Error get lesson:', error);
      });
  };

  const updateCourse = useCallback(() => {
    instanceFetch
      .post(
        `${process.env.REACT_APP_API_URL}/api/courses/${course.id}/pass`,
        {},
        { authorization: `Bearer ${token}` }
      )
      .then((response) => {
        dispatch(setUserInfoAction(response));
      })
      .then(() => {
        push('/dashboard/your-learnings');
      })
      .catch((error) => {
        console.error('Error update course:', error);
      });
  }, []);

  const updateLesson = useCallback(() => {
    instanceFetch
      .post(
        `${process.env.REACT_APP_API_URL}/api/lessons/${lesson.id}/pass`,
        {},
        { authorization: `Bearer ${token}` }
      )
      .then((response) => {
        dispatch(setUserInfoAction(response));
        push(lesson?.nextLesson?.slug);
      })
      .catch((error) => {
        console.error('Error update lesson:', error);
      });
  }, [lesson]);

  useEffect(() => {
    return getLesson(slug);
  }, [slug, pathname]);

  const nextClick = useCallback(async () => {
    if (lesson?.nextLesson) {
      await updateLesson();
    } else if (lesson?.nextLesson === null) {
      await updateLesson();
      await updateCourse();
    }
  }, [lesson]);

  const prevClick = useCallback(() => {
    const prevLesson = lesson?.previousLesson;
    if (prevLesson) {
      push(prevLesson?.slug);
    } else if (prevLesson === null) {
      push(`/dashboard/your-learnings/${course?.slug}`);
    }
  }, [lesson, course]);

  const text = lesson?.nextLesson === null ? 'Finish' : 'Next step';

  const renderContent = useMemo(() => {
    if (isEmpty(course)) return null;
    return (
      <div className="page__material">
        <div className="article-opened__wrapper">
          <div className="article-opened-content">
            <CourseModal
              isQuizAvailable={(course?.quiz && lesson?.nextLesson === null) || lesson?.quiz}
              handleOpenQuiz={handleOpenQuiz}
              nextClick={nextClick}
              prevClick={prevClick}
              text={text}
              course={course}
            >
              <h1 className="l-1">{lesson?.headline}</h1>
              {lesson?.content?.map((item, index) => createArticlesElement(item, index))}
            </CourseModal>
            {openQuiz && (
              <LessonQuiz
                quiz={course?.quiz || lesson?.quiz}
                courseId={course?.id || lesson?.id}
                handleOpenQuiz={handleOpenQuiz}
                nextLesson={nextClick}
                coursePassed={lesson?.nextLesson === null}
                isLessonQuiz={lesson?.quiz}
              />
            )}
          </div>
        </div>
      </div>
    );
  }, [pathname, lesson, course, openQuiz]);

  return (
    <>
      <div className="course-lessons">
        <div className="course-lessons__header"></div>
        <div className="course-lessons__body">
          <div className="course-lessons__body__content">{renderContent}</div>
        </div>
      </div>
    </>
  );
};

export default Lesson;
