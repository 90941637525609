import React, { useMemo } from 'react';
import ArticleProgressRow from '../../components/Articles/types/ArticleProgressRow';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
import { nav } from '../../common/PageTemplate/articleTypesFilter';
import ButtonIcon from '../../common/Button/ButtonIcon';
import useViewBtn from '../../common/PageTemplate/useViewBtn';
import useOnlySort from '../../common/PageTemplate/useOnlySort';
import ArticleProgress from '../../components/Articles/types/ArticleProgress';
import cx from 'classnames';

const LearningCompanyFolderCourses = ({ learnedCourses, getCoursesCategories }) => {
  const { handleViewListBtn, handleViewGridBtn, activeButton } = useViewBtn();
  const { selectedSort, onClickSort } = useOnlySort(learnedCourses?.courses, nav);

  const renderCourses = useMemo(() => {
    return learnedCourses?.courses.map((course, index) => {
      return activeButton === 'viewListBtn' ? (
        <ArticleProgressRow
          key={index}
          course={course}
          sizeToShow="md"
          onClickBookmark={getCoursesCategories}
        />
      ) : (
        <ArticleProgress
          key={index}
          course={course}
          sizeToShow="xs"
          onClickBookmark={getCoursesCategories}
        />
      );
    });
  }, [learnedCourses?.courses, activeButton, selectedSort]);

  const cardViewClass = cx(
    { 'cards-grid': activeButton === 'viewGridBtn' },
    { 'cards-grid-list-view': activeButton === 'viewListBtn' }
  );

  return (
    <>
      <div className="page-template__filter sort-and-view-btn">
        <DropdownLabelPage
          label={selectedSort}
          array={nav}
          selected={selectedSort}
          position="left"
          onClick={onClickSort}
        />
        <div className="page-filter-view-btn">
          <ButtonIcon
            icon="view-list-big"
            size="xxs"
            active={activeButton === 'viewListBtn'}
            onClick={handleViewListBtn}
          />
          <ButtonIcon
            icon="view-grid"
            size="xxs"
            active={activeButton === 'viewGridBtn'}
            onClick={handleViewGridBtn}
          />
        </div>
      </div>
      <div className={cardViewClass}>{renderCourses}</div>
    </>
  );
};

export default LearningCompanyFolderCourses;
