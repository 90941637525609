import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardArticlesSelector, tokenSelector } from '../../../../store/selectors';
import SubPagesFilterAndContent from '../../../../common/PageTemplate/SubPagesFilterAndContent';
import Button from '../../../../common/Button/Button';
import { useHistory } from 'react-router-dom';

import { instanceFetch } from '../../../../helper/instanceFetch';
import { getArticlesDashboard } from '../../../../store/actions/dashboardAction';
import { useGetBookmarks } from '../../../../helper/getBookmarks';

const RecommendedPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector(tokenSelector);
  const articles = useSelector(dashboardArticlesSelector);
  const getBookmarks = useGetBookmarks();

  // MAYBE THIS IS NOT NEEDED and USE /api/articles method
  const ROUTES = {
    DASHBOARD: `${process.env.REACT_APP_API_URL}/api/dashboard`,
  };
  const bookmarksHandler = () => {
    instanceFetch
      .get(ROUTES.DASHBOARD, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getArticlesDashboard(response));
      })
      .then(getBookmarks)
      .catch((error) => {
        console.error('Error fetching', error);
      });
  };
  const renderContent = useMemo(() => {
    return <SubPagesFilterAndContent articles={articles} getBookmarks={bookmarksHandler} />;
  }, [articles]);

  return (
    <div className="page-template__container">
      <Button
        className="button__back"
        iconLeft="arrow-left"
        text="Go to Dashboard"
        type="text"
        size="md"
        onClick={() => history.goBack()}
      />
      <div className="l-2">Recommended for you</div>

      {renderContent}
    </div>
  );
};

export default RecommendedPage;
