import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, createStore } from 'redux';
import authReducer from './reducers/authReducer';
import userReducer from './reducers/userReducer';
import confetti from './reducers/confettiReducer';
import articlesFeedReducer from './reducers/articleFeedReducer';
import { LOGOUT_SESSION } from './actions/logoutAction';
import dashboardReducer from './reducers/dashboardReducer';
import bookmarksReducer from './reducers/bookmarksReducer';
import commentsReducer from './reducers/commentsReducer';
import articleReducer from './reducers/articleReducer';
import courseReducer from './reducers/courseReducer';

const allReducers = combineReducers({
  authReducer,
  userReducer,
  confetti,
  articlesFeedReducer,
  dashboardReducer,
  bookmarksReducer,
  commentsReducer,
  articleReducer,
  courseReducer,
});

const reducer = (state, action) => {
  if (action.type === LOGOUT_SESSION) {
    storage.removeItem('persist:root');

    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(persistedReducer);

export let persistor = persistStore(store);
