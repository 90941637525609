import React, { useMemo, useState } from 'react';

const useOnlySort = (list, nav) => {
  const [selectedSort, setSelectedSort] = useState([nav[0]]);

  const onClickSort = (sort) => {
    setSelectedSort([sort]);
  };

  const getSortedList = useMemo(() => {
    if (selectedSort.includes('Newest')) {
      list.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    } else if (selectedSort.includes('Oldest')) {
      list.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    }

    return list;
  }, [list, selectedSort]);

  return {
    selectedSort,
    onClickSort,
    getSortedList,
  };
};

export default useOnlySort;
