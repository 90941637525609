import { GET_ARTICLE } from '../actions/getArticleAction';

const initialState = {
  article: [],
};

export default function articleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
      };
    default:
      return state;
  }
}
