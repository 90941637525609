import { GET_COURSE } from '../actions/getCourseAction';

const initialState = {
  course: [],
};

export default function courseReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COURSE:
      return {
        ...state,
        course: action.payload,
      };
    default:
      return state;
  }
}
