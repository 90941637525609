import React from 'react';
import Svg from '../../common/SVG';
import cx from 'classnames';

const QuizProgress = ({ questions, progressArr }) => {
  return (
    <div className="quiz__progress-bar">
      {questions.map((value, key) => {
        const progressClass = cx(
          'quiz__progress-bar-item',
          { success: progressArr[key] === true },
          { error: progressArr[key] === false }
        );

        return <div key={key} className={progressClass} />;
      })}
    </div>
  );
};

export default QuizProgress;
