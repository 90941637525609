import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import QuizProgress from './QuizProgress';
import LessonQuizFooter from './LessonQuizFooter';
import QuestionCard from './QuestionCard';
import QuestionCheckAnswer from './QuestionCheckAnswer';
import Button from '../../common/Button/Button';

const QuestionCardWrapper = ({
  questionsData,
  tries,
  counter,
  setCounter,
  failedAnswers,
  setFailedAnswers,
  setFinishedQuiz,
  setTotalScore,
  retake,
  retakeHandlerToStartCard,
  handleOpenQuiz,
  setUseAllTries,
}) => {
  const [checked, setChecked] = useState(false);
  const [checkedAnswerIndex, setCheckedAnswerIndex] = useState([]);
  const [progressArr, setProgressArr] = useState([]);
  const [totalResultAnswer, setTotalResultAnswer] = useState([]);
  const [answerEmpty, setAnswerEmpty] = useState(true);
  let multipleAnswer = 0;

  const answers = questionsData[counter].answer;
  const [checkAllAnswersIsTrue, setCheckAllAnswersIsTrue] = useState(0);
  const [singleQuestion, setSingleQuestion] = useState(false);
  const [multipleQuestion, setMultipleQuestion] = useState(false);

  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [incorrectAnswer, setInCorrectAnswer] = useState(false);

  const [score, setScore] = useState(0);
  useEffect(() => {
    answers.map((answer) => {
      if (answer.correct === true) {
        setCheckAllAnswersIsTrue((checkAllAnswersIsTrue) => checkAllAnswersIsTrue + 1);
      }
    });
    if (checkAllAnswersIsTrue > 0) {
      if (checkAllAnswersIsTrue === 1) {
        setSingleQuestion(true);
      } else if (checkAllAnswersIsTrue > 1) {
        setMultipleQuestion(true);
      }
    }
    if (counter > 0) {
      if (counter === questionsData.length - 1) {
        counter++;
      }
      setScore((100 * (counter - failedAnswers)) / questionsData.length);
    }

    if (questionsData.length === 1 && checkAllAnswersIsTrue > 0) {
      setScore(100);
    }

    return () => {
      setCheckAllAnswersIsTrue(0);
      setSingleQuestion(false);
      setMultipleQuestion(false);
      setScore(0);
    };
  }, [answers, checkAllAnswersIsTrue, singleQuestion, multipleQuestion, counter, failedAnswers]);

  const answerClass = cx('answers-list', { 'answers-list--disabled': checked });

  const scoreToInt = () => {
    if (Number.isInteger(score) === true) {
      return score;
    } else {
      return score.toFixed(2);
    }
  };

  const nextQuestionHandler = () => {
    setChecked(false);
    setCheckAllAnswersIsTrue(0);
    setCheckedAnswerIndex([]);
    setAnswerEmpty(true);
    setTotalResultAnswer([]);
    setCorrectAnswer(false);
    setInCorrectAnswer(false);
    if (singleQuestion) {
      setSingleQuestion(false);
    } else if (multipleQuestion) {
      setMultipleQuestion(false);
    }
    const nextQuestion = counter + 1;
    if (counter === questionsData.length - 1) {
      setUseAllTries(tries === failedAnswers);
      setCounter(0);
      setFinishedQuiz(true);
      setTotalScore(scoreToInt());
    } else if (counter < questionsData.length - 1) {
      setCounter(nextQuestion);
    }
  };

  const retakeClick = useCallback(() => {
    setUseAllTries(true);
    setFinishedQuiz(true);
    setCounter(0);
    setTotalScore(scoreToInt());
    setFailedAnswers(0);
    setCheckedAnswerIndex([]);
    setChecked(false);
  }, [tries, failedAnswers]);

  const answerClickHandler = (index) => {
    if (singleQuestion) {
      setCheckedAnswerIndex([index]);
    } else {
      if (checkedAnswerIndex.includes(index)) {
        setCheckedAnswerIndex(checkedAnswerIndex.filter((item) => item !== index));
      } else {
        setCheckedAnswerIndex([...checkedAnswerIndex, index]);
      }
    }
  };

  const renderBtn = useMemo(() => {
    if (retake) {
      return (
        <LessonQuizFooter
          retakeHandlerToStartCard={retakeHandlerToStartCard}
          backToLesson
          backToLessonHandler={() => {
            handleOpenQuiz(false);
          }}
        />
      );
    }
    if (checked) {
      return (
        <Button
          className="next-btn"
          iconRight="arrow-right-stroke"
          text="Continue"
          type="primary"
          size="md"
          onClick={nextQuestionHandler}
        />
      );
    }
    if (checkedAnswerIndex && checkedAnswerIndex.length > 0) {
      return (
        <QuestionCheckAnswer
          checkedAnswerIndex={checkedAnswerIndex}
          setChecked={setChecked}
          totalResultAnswer={totalResultAnswer}
          setTotalResultAnswer={setTotalResultAnswer}
          setAnswerEmpty={setAnswerEmpty}
          setFailedAnswers={setFailedAnswers}
          failedAnswers={failedAnswers}
          answers={answers}
          setProgressArr={setProgressArr}
          progressArr={progressArr}
          retakeHandlerToStartCard={retakeClick}
          tries={tries}
          singleQuestion={singleQuestion}
          multipleQuestion={multipleQuestion}
          multipleAnswer={multipleAnswer}
          checkAllAnswersIsTrue={checkAllAnswersIsTrue}
          checked={checked}
          setCorrectAnswer={setCorrectAnswer}
          setInCorrectAnswer={setInCorrectAnswer}
        />
      );
    } else {
      return null;
    }
  }, [answers, totalResultAnswer, answerEmpty, checkedAnswerIndex, answerClickHandler]);

  const renderQuestionCard = useMemo(() => {
    return (
      <QuestionCard
        answerClass={answerClass}
        answers={answers}
        answerEmpty={answerEmpty}
        totalResultAnswer={totalResultAnswer}
        answerClickHandler={answerClickHandler}
        checkedAnswerIndex={checkedAnswerIndex}
        incorrectDescription={questionsData[counter].incorrectDescription}
        correctDescription={questionsData[counter].correctDescription}
      />
    );
  }, [answers, totalResultAnswer, answerEmpty, checkedAnswerIndex, answerClickHandler]);

  const renderAnswerDesc = useMemo(
    () => (
      <>
        {incorrectAnswer && (
          <span className="answer-description error">
            {questionsData[counter].incorrectDescription}
          </span>
        )}
        {correctAnswer && (
          <span className="answer-description success">
            {questionsData[counter].correctDescription}
          </span>
        )}
      </>
    ),
    [incorrectAnswer, correctAnswer]
  );
  return (
    <>
      <QuizProgress questions={questionsData} progressArr={progressArr} />

      <div className="checkbox-question-item">
        <div className="question-title">{questionsData[counter]?.question}</div>
        <div className="question-description">{questionsData[counter]?.description}</div>
        {renderAnswerDesc}
        {renderQuestionCard}
        <div className="lesson-quiz__card-footer">{renderBtn}</div>
      </div>
    </>
  );
};

export default QuestionCardWrapper;
