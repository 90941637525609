import { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { excludeRoute } from '../../constants';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import { isEmpty } from 'lodash';

const Guard = (props) => {
  const token = useSelector(tokenSelector);

  const {
    location: { pathname },
    children,
  } = props;

  if (excludeRoute.includes(pathname) || token) {
    return children;
  }

  // if (loading) {
  //   return null;
  // }

  if (isEmpty(token)) {
    return <Redirect to={{ pathname: '/', state: pathname }} />;
  }

  return null;
};

export default memo(Guard);
