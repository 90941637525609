import React from 'react';
import cx from 'classnames';
import TimelineItem from './TimelineItem';
import { SwiperSlide } from 'swiper/react';
import SwipeWrapper from '../../common/SwipeWrapper/SwipeWrapper';

const Timeline = ({ swiper, vertical, timeLineArray }) => {
  const timelineClass = cx('timeline-container', { vertical });
  return (
    <div className={timelineClass}>
      {swiper ? (
        <SwipeWrapper className="swipe-timeline-item">
          {timeLineArray.map(({ type, title }) => {
            return (
              <SwiperSlide key={title}>
                <TimelineItem type={type} title={title} />
              </SwiperSlide>
            );
          })}
        </SwipeWrapper>
      ) : (
        timeLineArray.map(({ type, title }) => {
          return <TimelineItem key={title} type={type} title={title} />;
        })
      )}
    </div>
  );
};

export default Timeline;
