import React from 'react';
import cx from 'classnames';
import Svg from '../SVG';

const Badge = ({ label, className, size, isIcon = true, color = 'red' }) => {
  const badgeClass = cx('badge', className, size, color);

  return (
    <div className={badgeClass}>
      <div className="badge__text">{label}</div>
      {isIcon && <Svg className="badge__icon" name="coin" />}
    </div>
  );
};

export default Badge;
