import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { courseSelector, tokenSelector } from '../../../store/selectors';
import { createArticlesElement } from '../../../helper';
import Metrics from '../../Articles/opened/Metrics';
import ArticleDefaultFooter from '../../Articles/opened/ArticleDefaultFooter';
import SidebarActions from '../../Articles/opened/SidebarActions';
import { useGetCourseBySlug } from '../../../helper/getCourse';
import { getCourseAction } from '../../../store/actions/getCourseAction';
import { isEmpty } from 'lodash';
import StartLessonBtn from './StartLessonBtn';
import People from '../../../common/People/People';

const CourseOpened = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const course = useSelector(courseSelector);
  const getCourse = useGetCourseBySlug({ slug: slug });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articleData = await getCourse();
        dispatch(getCourseAction(articleData));
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchData();
  }, [getCourse]);

  if (!course || isEmpty(course)) return null;

  return (
    <div className="page__material">
      <div className="article-opened">
        <div className="article-opened__wrapper">
          <div className="article-opened-content">
            <h1 className="l-1">{course?.headline}</h1>
            <Metrics article={course} />
            {course?.content.map((item, index) => createArticlesElement(item, index))}
            <div className="course-actions__footer">
              <StartLessonBtn course={course} />
              <div className="course-participants">
                <People maxPeopleToDisplay={3} people={course?.participants} />
                <span className="course-participants__label">
                  {course?.participants.length} learners
                </span>
              </div>
            </div>
          </div>
          <ArticleDefaultFooter article={course} type="course" />
        </div>
        <div className="article-opened-sidebar">
          <SidebarActions article={course} type="course" />
          <StartLessonBtn course={course} />
        </div>
      </div>
    </div>
  );
  // return null
};

export default CourseOpened;
