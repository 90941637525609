import React from 'react';

const Label = ({ text, id }) => {
  return (
    <label className="field__label" htmlFor={id}>
      {text}
    </label>
  );
};

export default Label;
