import React from 'react';
import Button from '../../common/Button/Button';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const LessonQuizStartCard = ({ startCardHandler, quizTitle }) => {
  const isMobile = false;
  return (
    <div className="lesson-quiz__card starter-card">
      <div className="lesson-quiz__card-inner">
        {!isMobile && (
          <>
            <div className="lesson-quiz__card-title">Lesson quiz</div>
            <div className="lesson-quiz__card-description">{quizTitle}</div>
          </>
        )}
        <img className="lesson-quiz__card-content-img" src="/images/instruction.gif" alt="" />
        <div className="lesson-quiz__card-description">
          On the upper right corner, you’ll see a series of hearts. Every wrong answer removes a
          heart. When all your hearts are gone, you’ll be asked to retake the test.
        </div>
        <div className="quiz-footer-btn">
          <Button onClick={startCardHandler} text="Got it!" type="primary" size="md" />
        </div>
      </div>
    </div>
  );
};

export default LessonQuizStartCard;
