import React, { useMemo, useState } from 'react';
import Svg from '../../common/SVG';

const QuizTry = ({ tries, retake, failedAnswers }) => {
  const renderHearts = useMemo(() => {
    const hearts = ['heart-low', 'heart-low', 'heart-low'];

    if (tries - failedAnswers >= 1) {
      hearts[0] = 'heart-full';
    }
    if (tries - failedAnswers >= 2) {
      hearts[1] = 'heart-full';
    }
    if (tries - failedAnswers === 3) {
      hearts[2] = 'heart-full';
    }

    return hearts.map((heartClass, index) => (
      <Svg key={index} name="heart" className={heartClass} />
    ));
  }, [failedAnswers]);

  return (
    <div className="lesson-quiz__card-hearts">
      <span className="lesson-quiz__card-hearts-title">
        {retake ? 'You ran out of hearts!' : `${tries - failedAnswers} hearts left`}
      </span>
      {renderHearts}
    </div>
  );
};

export default QuizTry;
