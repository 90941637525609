import React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarAuthDesktop from './SidebarAuthDesktop';

import SidebarCourse from './SidebarCourse';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';

const pathOne = /\/dashboard\/your-learnings\/[^/]+\/[^/]+/;
const pathTwo = '/preparing-material';

const SideBar = () => {
  const user = useSelector(userSelector);
  const { pathname } = useLocation();

  if (!user?.progress?.onboardingPassed) return null;

  if (pathOne.test(pathname) || pathTwo === pathname) {
    return <SidebarCourse />;
  }

  return <SidebarAuthDesktop />;
};

export default SideBar;
