import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Tab from '../../common/Tab/Tab';
import { Dropdown } from 'antd';
import { excludeRoute } from '../../constants';
import ButtonIcon from '../../common/Button/ButtonIcon';
import Svg from '../../common/SVG';
import cx from 'classnames';
import DrawerModal from '../../common/Drawer/Drawer';
import SidebarAuthMobile from '../Sidebar/SidebarAuthMobile';
import Avatar from '../../common/Avatar/Avatar';
import SearchData from './SearchData';
import { logoutAction } from '../../store/actions/logoutAction';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';

const HeaderMobile = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user = useSelector(userSelector);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isVisibleSearch, setVisibleSearch] = useState(false);

  const handleSearchInput = () => {
    setVisibleSearch(!isVisibleSearch);
  };

  const handleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    push('/');
  };

  const accountMenu = (
    <>
      {/*<div className="dropdown-content-list__nav">*/}
      {/*  {accountNav.map(({ name, icon, to }, index) => (*/}
      {/*    <Link to={to} key={index}>*/}
      {/*      <Tab key={name} textLeft={name} iconLeft={icon} block color="whitePurple" />*/}
      {/*    </Link>*/}
      {/*  ))}*/}
      {/*</div>*/}

      <Tab textLeft="Log out" iconLeft="logout" block color="whitePurple" onClick={handleLogout} />
    </>
  );

  if (excludeRoute.includes(pathname) || !user) {
    return null;
  }

  return (
    <header className={cx('header mobile')}>
      <div className="header__inner">
        <button onClick={handleSidebar} type="button" className="header__burger">
          <Svg name="menu-collapsed" />
        </button>
        <Link className="header-logo" to="/">
          <img className="header-logo__img" src="/images/logo.png" alt="logo" />
        </Link>

        <div className="header__controls">
          <div className="header-search">
            <ButtonIcon
              icon="search"
              size="xs"
              active={isVisibleSearch}
              onClick={handleSearchInput}
            />
          </div>

          <div className="header-notification">
            <div className="header-notification">
              <ButtonIcon disabled icon="bell" size="xs" />
            </div>
          </div>

          <div className="header-account">
            <Dropdown
              overlay={accountMenu}
              trigger={['click']}
              placement="bottomRight"
              className="header-account-dropdown"
              overlayClassName="header-account-modal-mobile"
            >
              <div onClick={(e) => e.preventDefault()}>
                <Avatar size="lg" author={user.username} avatarImg={user.avatar} />
                <Svg className="icon-arrow" name="arrow-down" />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      {isVisibleSearch && <SearchData />}

      {isSidebarVisible && (
        <DrawerModal
          isBurgerMenu
          width={240}
          placement="left"
          isDrawerVisible={isSidebarVisible}
          handleClick={handleSidebar}
          className={cx('modal-sidebar')}
        >
          <SidebarAuthMobile handleSidebar={handleSidebar} />
        </DrawerModal>
      )}
    </header>
  );
};

export default HeaderMobile;
