import React from 'react';
import Answer from './Answer';

const QuestionCard = ({
  answerClass,
  answers,
  answerEmpty,
  totalResultAnswer,
  answerClickHandler,
  checkedAnswerIndex,
  incorrectDescription,
  correctDescription,
}) => {
  return (
    <ul className={answerClass}>
      {answers.map((answer, index) => {
        const checkedAnswer = !answerEmpty && checkedAnswerIndex.includes(index);

        const correctAnswer = checkedAnswer && totalResultAnswer[index].success === true;
        const incorrectAnswer = checkedAnswer && totalResultAnswer[index].error === true;
        const notSelectedAnswer =
          !answerEmpty &&
          !checkedAnswerIndex.includes(index) &&
          totalResultAnswer[index].notSelect === true;

        return (
          <Answer
            key={index}
            index={index}
            answerText={answer.answerText}
            correctDescription={correctDescription}
            incorrectDescription={incorrectDescription}
            correctAnswer={correctAnswer}
            incorrectAnswer={incorrectAnswer}
            notSelectedAnswer={notSelectedAnswer}
            handleClick={() => answerClickHandler(index)}
            checked={checkedAnswerIndex.includes(index)}
          />
        );
      })}
    </ul>
  );
};
export default QuestionCard;
