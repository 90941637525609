import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  dashboardBookmarksSelector,
  tokenSelector,
  userSelector,
} from '../../../../store/selectors';
import Button from '../../../../common/Button/Button';
import ArticleProgress from '../../../../components/Articles/types/ArticleProgress';
import TabsList from '../../../../common/Tab/TabsList';
import DropdownLabelPage from '../../../../components/Dropdown/DropdownLabelPage';
import dayjs from 'dayjs';
import { instanceFetch } from '../../../../helper/instanceFetch';
import WelcomeBlock from '../../../../common/WelcomeBlock/WelcomeBlock';

const NEWEST = 'Last added';
const OLDEST = 'First added';
const MOST_PROGRESS = 'More completed first';
const LEAST_PROGRESS = 'Less completed first';
const COMPLETED_LAST = 'Completed last';
const COMPLETED_FIRST = 'Completed first';

const YourLearningPage = () => {
  const history = useHistory();
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedSort, setSelectedSort] = useState('');
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [coursesCompleted, setCoursesCompleted] = useState([]);

  const sortOptionsInProgress = [NEWEST, OLDEST, MOST_PROGRESS, LEAST_PROGRESS];
  const sortOptionsCompleted = [COMPLETED_LAST, COMPLETED_FIRST];

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
  });

  const ROUTES = {
    COURSES: `${process.env.REACT_APP_API_URL}/api/courses`,
  };

  useEffect(() => {
    setSelectedSort(yourLearningTabs[activeTab]?.sortOptions?.[0]);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 0) {
      setCoursesInProgress((prevCourses) => sortProgressCourses([...prevCourses], selectedSort));
    } else if (activeTab === 1) {
      setCoursesCompleted((prevCourses) => sortCompletedCourses([...prevCourses], selectedSort));
    }
  }, [activeTab, selectedSort]);

  const getYourLearningCourses = useCallback(() => {
    instanceFetch
      .get(ROUTES.COURSES, { authorization: `Bearer ${token}` })
      .then((response) => {
        const coursesInProgress = response.data.filter((course) => !course.progress.finished);
        const coursesCompleted = response.data.filter(
          (course) => course.progress.finished || course.progress.passed === course.progress.total
        );

        setCoursesInProgress(coursesInProgress);
        setCoursesCompleted(coursesCompleted);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getYourLearningCourses();
  }, []);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    setSelectedSort(yourLearningTabs[tabIndex]?.sortOptions?.[0]);
  };

  const sortProgressCourses = (courses, sortOption) => {
    const sortByPublishedAt = (a, b) => {
      const dateA = dayjs(a.publishedAt);
      const dateB = dayjs(b.publishedAt);
      return (dateB.isValid() ? dateB.unix() : 0) - (dateA.isValid() ? dateA.unix() : 0);
    };

    const sortByProgress = (a, b) => (b.progress?.passed || 0) - (a.progress?.passed || 0);

    const sortFunctions = {
      [NEWEST]: sortByPublishedAt,
      [OLDEST]: (a, b) => -sortByPublishedAt(a, b),
      [MOST_PROGRESS]: sortByProgress,
      [LEAST_PROGRESS]: (a, b) => -sortByProgress(a, b),
    };

    return [...courses]
      .filter((course) => course && course.publishedAt)
      .sort(sortFunctions[sortOption])
      .map((course) => ({ ...course, sortValue: sortFunctions[sortOption](course, {}) }));
  };

  const sortCompletedCourses = (courses, sortOption) => {
    const sortByFinishedAt = (a, b) => {
      const dateA = dayjs(a.updatedAt);
      const dateB = dayjs(b.updatedAt);
      return (dateB.isValid() ? dateB.unix() : 0) - (dateA.isValid() ? dateA.unix() : 0);
    };

    const sortFunctions = {
      [COMPLETED_LAST]: sortByFinishedAt,
      [COMPLETED_FIRST]: (a, b) => -sortByFinishedAt(a, b),
    };

    return [...courses]
      .filter((course) => course && course.updatedAt)
      .sort(sortFunctions[sortOption])
      .map((course) => ({ ...course, sortValue: sortFunctions[sortOption](course, {}) }));
  };

  const renderProgressCourses = useCallback(
    (courses) => {
      return courses.map((course, index) => (
        <ArticleProgress
          key={index}
          course={course}
          sizeToShow={windowWidth < 1440 ? 'xs' : 'md'}
          onClickBookmark={() => getYourLearningCourses()}
        />
      ));
    },
    [windowWidth]
  );

  const renderCompletedCourses = useCallback(
    (courses) => {
      return courses.map((course, index) => (
        <ArticleProgress
          key={index}
          course={course}
          sizeToShow={windowWidth < 1440 ? 'xs' : 'md'}
          onClickBookmark={() => getYourLearningCourses()}
        />
      ));
    },
    [windowWidth]
  );

  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);

    if (activeTab === 0) {
      setCoursesInProgress((prevCourses) => sortProgressCourses([...prevCourses], sortOption));
    } else if (activeTab === 1) {
      setCoursesCompleted((prevCourses) => sortCompletedCourses([...prevCourses], sortOption));
    }
  };

  const yourLearningTabs = [
    {
      label: 'In progress',
      content: renderProgressCourses(coursesInProgress),
      sortOptions: sortOptionsInProgress,
    },
  ];

  if (coursesCompleted.length > 0) {
    yourLearningTabs.push({
      label: 'Completed',
      content: renderCompletedCourses(coursesCompleted),
      sortOptions: sortOptionsCompleted,
    });
  }

  return (
    <div className="page-template__container learning-page">
      <div className="l-2">Your learning</div>

      {!Boolean(user?.progress?.learningPassed) && (
        <WelcomeBlock
          type="learningPassed"
          title="Welcome to Learnings"
          description="Discover publications your teammates and friends share"
        />
      )}

      <div className="page-template__filter page-template__filter_not-single">
        <TabsList tabs={yourLearningTabs} activeTab={activeTab} setActiveTab={handleTabChange} />
        <DropdownLabelPage
          label={selectedSort}
          array={activeTab === 0 ? sortOptionsInProgress : sortOptionsCompleted}
          selected={selectedSort}
          position="right"
          onClick={(sortOption) => handleSortChange(sortOption)}
        />
      </div>
      <div className="page-template__content">
        <div className="page-template__articles">{yourLearningTabs[activeTab].content}</div>
      </div>
    </div>
  );
};

export default YourLearningPage;
