export const GET_USER_INFO = 'GET_USER_INFO';
export const SET_USER_INFO = 'SET_USER_INFO';
export function getUserInfoAction(user) {
  return {
    type: GET_USER_INFO,
    payload: user,
  };
}

export function setUserInfoAction(user) {
  return {
    type: SET_USER_INFO,
    payload: user,
  };
}
