import React from 'react';
import cx from 'classnames';

const DashboardBlockInner = ({ children, className }) => {
  const blockClassName = cx('dashboard__block', className);

  return <div className={blockClassName}>{children}</div>;
};

export default DashboardBlockInner;
