import React, { useMemo } from 'react';
import Bookmark from '../../../common/Bookmark/Bookmark';
import TagLabel from '../../../common/Tag/TagLabel';
import cx from 'classnames';
import { getImageURL } from '../../../helper';

const ArticleContentAbsolute = ({ article, onClickBookmark, type, coverImage, hideBookmark }) => {
  const renderBookmark = useMemo(() => {
    if (hideBookmark) return null;
    return (
      <Bookmark
        type={article.hasOwnProperty('lessons') ? 'course' : 'article'}
        article={article}
        onClickBookmark={onClickBookmark}
      />
    );
  }, [article]);
  return (
    <div className="article-item__content">
      <div
        className={cx('article-item__content-image', {
          'article-item__content-image--no-cover': !coverImage,
        })}
      >
        {coverImage?.url && <img src={getImageURL(coverImage.url)} alt="" />}
      </div>

      <div className="article-item__content-absolute">
        <div className="article-item__content-bookmark">{renderBookmark}</div>
        {type && (
          <div className="article-item__content-type">
            <TagLabel text={type?.name} icon={(type?.name).toLowerCase()} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleContentAbsolute;
