import React, { useMemo, useState } from 'react';
import CommentModal from './CommentModal';
import Svg from '../SVG';
import { commentsSelector } from '../../store/selectors';
import { useSelector } from 'react-redux';

const Comment = ({ article, showLabelAction, type }) => {
  const { id } = article;
  const [openModal, setOpenModal] = useState(false);
  const { commentsCount, comments } = article;
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenModal((openModal) => !openModal);
  };

  return useMemo(
    () => (
      <>
        <div className="comments-container" onClick={handleClick}>
          <Svg name="comment" />
          {showLabelAction &&
            (commentsCount ? <span>{commentsCount} comments</span> : <span>Comment</span>)}
        </div>
        {openModal && (
          <CommentModal
            openModal={openModal}
            handleClick={handleClick}
            id={id}
            type={type}
            comments={comments}
          />
        )}
      </>
    ),
    [openModal, article, showLabelAction]
  );
};

export default Comment;
