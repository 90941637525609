import React from 'react';
import cx from 'classnames';
import Svg from '../SVG';

const Checkbox = ({ list, id, text, disabled, checked, onClick }) => {
  const checkboxClassName = cx(
    'checkbox',
    disabled && 'disabled',
    checked && !disabled && 'checked'
  );

  return (
    <div className={checkboxClassName} onClick={onClick}>
      <button type="button" className="checkbox__btn" id={id}>
        {checked && (list ? <div className="icon">-</div> : <Svg name="checkmark" />)}
      </button>

      {text && (
        <span className="checkbox__label" htmlFor={id}>
          {text}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
