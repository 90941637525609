import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { sectionHeader } from '../Dashboard';
import UpcomingEventsItem from '../UpcomingEventsItem';
import ModalWrapper from '../../../components/Modals/ModalWrapper';

const DashboardEvents = ({ events }) => {
  const [openAllEvents, setOpenAllEvents] = useState(false);
  const isEmptyEvents = isEmpty(events);

  const renderEmptyEvents = useMemo(() => {
    return (
      <div className="dashboard__events-empty">
        {/*TODO: add organization name*/}
        <div className="dashboard__events-empty-title">Yolo hasn’t added any events yet</div>
      </div>
    );
  }, [events]);

  const renderEvents = useMemo(() => {
    return events.map((event, index) => <UpcomingEventsItem key={index} event={event} />);
  }, [events]);

  const onClickSeeAll = () => {
    setOpenAllEvents((openAllEvents) => !openAllEvents);
  };

  return (
    <div className="dashboard__events dashboard-box">
      {sectionHeader('Upcoming events', null, isEmptyEvents, onClickSeeAll)}
      <div className="dashboard__events-content upcoming-events-list">
        {isEmptyEvents ? renderEmptyEvents : renderEvents}
      </div>

      {openAllEvents && (
        <ModalWrapper
          className="modal__upcoming-events"
          isModalVisible={openAllEvents}
          handleClick={onClickSeeAll}
        >
          <div className="dashboard modal__upcoming-events-content">
            <div className="dashboard__section-header">
              <div className="dashboard__section-header-title">Upcoming events</div>
            </div>
            <div className="dashboard__events-content upcoming-events-list">{renderEvents}</div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};

export default DashboardEvents;
