import React, { useCallback, useMemo, useState } from 'react';
import Tab from '../../common/Tab/Tab';
import SearchInput from '../../common/SearchInput/SearchInput';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';
import useUserFollow from '../../common/useUserFollow';

const TYPES_SEARCH = {
  articles: {
    iconLeft: 'article',
    typeRight: 'category',
    color: 'whitePurple',
    formatIcon: 'article',
    formatText: 'Article',
    to: '/article',
    mainKey: 'headline',
  },
  courses: {
    iconLeft: 'learning',
    typeRight: 'category',
    color: 'whitePurple',
    formatIcon: 'learning',
    formatText: 'Course',
    to: '/dashboard/your-learnings',
    mainKey: 'headline',
  },
  users: {
    avatar: true,
    typeRight: 'follow',
    color: 'whitePurple',
    // to: '/profile',
    mainKey: 'username',
  },
};

const SearchData = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);
  const [searchInput, setSearchInput] = useState('');
  const { push } = useHistory();
  const [showResult, setShowResult] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const { onClickFollow, onClickUnFollow } = useUserFollow();

  const renderSearch = useMemo(() => {
    return Object.entries(searchResult).map(([key, value]) => {
      return (
        <div className="popups__group" key={key}>
          <div className="popups__title">
            {key} <span>({value.length})</span>
          </div>
          <div className="popups__content">
            {value.map((item) => {
              const { mainKey, to } = TYPES_SEARCH[key];
              const onClick = () => push(`${to}/${item.slug}`);
              const searchValue = searchInput.toLowerCase();
              const highlightText = (text) => {
                const regex = new RegExp(searchValue, 'gi');
                return text.replace(
                  regex,
                  (match) => `<span class="search-highlight">${match}</span>`
                );
              };

              const highlightedText = highlightText(item[mainKey]);

              const isFollowing =
                user?.followings && user?.followings.some((following) => following.id === item.id);

              return (
                <Tab
                  key={item.id}
                  id={item.id}
                  {...TYPES_SEARCH[key]}
                  textLeft={[
                    <span key="text" dangerouslySetInnerHTML={{ __html: highlightedText }} />,
                  ]}
                  avatarText={item[mainKey]}
                  onClickFollow={() =>
                    isFollowing ? onClickUnFollow(item.id) : onClickFollow(item.id)
                  }
                  active={key === 'users' ? isFollowing : null}
                  type={key}
                  onClick={key === 'users' ? null : onClick}
                  className="search-tab"
                />
              );
            })}
          </div>
        </div>
      );
    });
  }, [searchResult, user]);

  const goToToSearch = useCallback(
    ({ code }) => {
      if (code === 'Enter') {
        push(`/search?${searchInput}`);
        setShowResult(false);
      }
    },
    [push, searchInput]
  );

  return (
    <SearchInput
      id="header-search"
      value={searchInput}
      setSearch={setSearchInput}
      showChildren={showResult}
      close={() => setShowResult(false)}
      goToToSearch={goToToSearch}
      setSearchResult={setSearchResult}
      setShowResult={setShowResult}
      placeholder="Search for everything..."
    >
      {showResult && !isEmpty(searchResult) && renderSearch}
    </SearchInput>
  );
};
export default SearchData;
