import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import TagButton from '../../common/Tag/TagButton';
import _ from 'lodash';
import { instanceFetch } from '../../helper/instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import { setUserInfoAction } from '../../store/actions/getUserInfoAction';
import { logoutAction } from '../../store/actions/logoutAction';
import { useHistory } from 'react-router-dom';

const onClick = (fn, prev, item) => () => {
  if (_.includes(prev, item)) {
    return fn([..._.remove(prev, (i) => i !== item)]);
  }
  return fn([...prev, item]);
};

const UserOnboardingSecondStep = ({ handleStep }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const isMobile = false;
  const token = useSelector(tokenSelector);
  const [selected, setSelected] = useState([]);

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/topics`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setTopics(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const nextStep = useCallback(() => {
    instanceFetch
      .put(
        `${process.env.REACT_APP_API_URL}/api/me`,
        {
          topics: _.map(
            _.filter(topics, (i) => _.includes(selected, i.name)),
            'id'
          ),
        },
        {
          authorization: `Bearer ${token}`,
        }
      )
      .then((response) => {
        dispatch(setUserInfoAction(response));
        handleStep((p) => p + 1);
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
        dispatch(logoutAction());
        push('/');
      });
  }, [topics]);

  return (
    <>
      <div className="onboarding__title">Let us know your interests</div>
      <div className="onboarding__subtitle">
        Please, select at least 3. You can always change them later.
      </div>

      <div className="onboarding-tags">
        <div className="onboarding-tags__list">
          {topics.map(({ name, id }) => (
            <TagButton
              key={id}
              text={name}
              icon="topic-filled"
              size="lg"
              onClick={onClick(setSelected, selected, name)}
              active={_.includes(selected, name)}
            />
          ))}
        </div>

        <div className="onboarding-footer">
          <div className="onboarding-footer__buttons">
            <Button
              onClick={() => handleStep((p) => p - 1)}
              text="Back"
              type="outline-purple"
              size={isMobile ? 'md' : 'lg'}
              block={isMobile}
            />

            <Button
              onClick={nextStep}
              text="Next step"
              type="primary"
              size={isMobile ? 'md' : 'lg'}
              block={isMobile}
              disabled={selected.length < 2}
            />
          </div>

          <div className="onboarding-footer__filled">
            {selected.length} from {topics.length} interests selected
          </div>
        </div>
      </div>
    </>
  );
};

export default UserOnboardingSecondStep;
