import React from 'react';
import cx from 'classnames';
import Svg from '../../common/SVG';

const timelineObj = {
  done: <Svg className="timeline-item-done" name="checkmark-circle" />,
  progress: <span className="timeline-item-progress" />,
  waiting: <span className="timeline-item-waiting" />,
};

const TimelineItem = ({ type, title }) => {
  const timeItemClass = cx('timeline-steps__item', type);
  return (
    <div className={timeItemClass}>
      <div>
        {timelineObj[type]}
        <span className="timeline-item-title" title={title}>
          {title}
        </span>
      </div>
      {/*TODO: refactor with after, now after donot work*/}
      <span className="timeline-item-line" />
    </div>
  );
};

export default TimelineItem;
