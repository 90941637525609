import React from 'react';
import LoginWithGoogle from '../Login/LoginWithGoogle';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import { isEmpty } from 'lodash';

const Landing = () => {
  const token = useSelector(tokenSelector);

  if (!isEmpty(token)) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  return (
    <div className="page__landing">
      <Link className="landing-logo" to="/">
        <img className="sidebar-logo__img" src="/images/circle.io-logo.svg" alt="logo" />
      </Link>
      <LoginWithGoogle />
    </div>
  );
};

export default Landing;
