import {
  GET_ALL_ARTICLES_FEED,
  SET_ALL_ARTICLES_FEED,
  GET_FOLLOWING_ARTICLES_FEED,
  SET_FOLLOWING_ARTICLES_FEED,
} from '../actions/articleFeedAction';

const initialState = {
  articles: [],
  articlesFollowing: [],
};

export default function articlesFeedReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ARTICLES_FEED:
      return {
        ...state,
        articles: action.payload,
      };
    case GET_FOLLOWING_ARTICLES_FEED:
      return {
        ...state,
        articlesFollowing: action.payload,
      };
    default:
      return state;
  }
}
