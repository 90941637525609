import React, { useState } from 'react';

const useViewButtons = () => {
  const [activeButton, setActiveButton] = useState('viewGridBtn');

  const handleViewListBtn = () => {
    setActiveButton('viewListBtn');
  };

  const handleViewGridBtn = () => {
    setActiveButton('viewGridBtn');
  };

  return { handleViewListBtn, handleViewGridBtn, activeButton };
};

export default useViewButtons;
