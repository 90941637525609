import React from 'react';
import cx from 'classnames';
import Svg from '../SVG';

const ButtonIcon = (props) => {
  const {
    type = 'primary',
    size = 'xs',
    active,
    icon,
    disabled,
    className,
    onClick,
    children,
    ...otherProps
  } = props;

  const buttonClassName = cx(
    'btn-icon',
    size && `${size}`,
    type && `${type}`,
    active && 'active',
    disabled && 'disabled',
    className
  );

  return (
    <button
      onClick={onClick}
      type="button"
      className={buttonClassName}
      disabled={disabled}
      {...otherProps}
    >
      {icon && <Svg name={icon} />}
    </button>
  );
};

export default ButtonIcon;
