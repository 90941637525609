import React from 'react';
import Avatar from '../Avatar/Avatar';

const People = ({ amount, people, maxPeopleToDisplay }) => {
  const peopleToDisplay = maxPeopleToDisplay ? people.slice(0, maxPeopleToDisplay) : people;
  return (
    <div className="people-list">
      {peopleToDisplay.map((value) => {
        if (!value) {
          return null;
        }
        return (
          <Avatar
            key={value.id}
            className="people-item__user"
            avatarImg={value?.avatar}
            author={value?.username}
          />
        );
      })}
      {amount && <div className="people-item__amount">{amount}</div>}
    </div>
  );
};

export default People;
