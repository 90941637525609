import React from 'react';
import cx from 'classnames';
import Likes from '../../../common/Likes/Likes';
import Bookmark from '../../../common/Bookmark/Bookmark';
import Comment from '../../../common/Comment/Comment';

const ArticleOpenedActions = ({ article, showLabelAction, col = false, type }) => {
  return (
    <div className={cx('article-opened-actions', { 'article-opened-actions_col': col })}>
      <div className="article-opened-actions__item">
        <Likes type={type} article={article} showLabelAction={showLabelAction} />
      </div>
      <div className="article-opened-actions__item">
        <Bookmark type={type} article={article} showLabelAction={showLabelAction} />
      </div>
      <div className="article-opened-actions__item">
        <Comment type={type} article={article} showLabelAction={showLabelAction} />
      </div>
    </div>
  );
};

export default ArticleOpenedActions;
