import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import 'swiper/swiper-bundle.css';
import { sectionHeader } from '../Dashboard';
import ArticleRelated from '../../../components/Articles/types/ArticleRelated';
import { useSelector } from 'react-redux';
import { dashboardSelector } from '../../../store/selectors';

const DashboardRecommended = () => {
  const dashboard = useSelector(dashboardSelector);
  const { articles } = dashboard;
  const isEmptyArticles = isEmpty(articles);

  const renderArticles = useMemo(() => {
    return articles.map((article, index) => (
      <ArticleRelated key={article.id} article={article} sizeToShow="md" />
    ));
  }, [articles]);

  const disableSeeAll = isEmptyArticles || articles.length < 2;

  if (isEmptyArticles) return null;

  return (
    <div className="dashboard__recommended">
      {sectionHeader('Recommended for you', '/feed', disableSeeAll)}
      <div className="dashboard__recommended-content">{renderArticles}</div>
    </div>
  );
};

export default DashboardRecommended;
