import { gql } from '@apollo/client';

export const KNOWLEDGE_BASES = gql`
  fragment knowledgeBase on KnowledgeBase {
    id
    name
    description
    coverImage {
      url
    }
    author {
      ...user
    }
    coverImagePattern {
      coverImage {
        url
      }
    }
    backgroundHEX
  }
`;

export const ORGANIZATION = gql`
  fragment organization on Organization {
    id
    name
    owner {
      ...user
    }
    logo {
      url
    }
    courses {
      id
    }
    departments {
      id
      name
    }
  }
`;

export const COURSE = gql`
  fragment course on Course {
    id
    validTo
    headline
    author {
      ...user
    }
    organization {
      id
      name
      logo {
        url
      }
    }
    subtitle
    shortLead
    coverImage {
      url
    }
    contents
    quiz {
      id
    }
    lessons {
      ...lesson
    }
    usersPassed {
      ...user
    }
    interests {
      interestTitle
    }
    usersStarted {
      ...user
    }
    comments {
      ...comment
    }
    likes {
      ...like
    }
    department {
      id
    }
    metrics
    slug
    published_at
  }
`;

export const QUIZ = gql`
  fragment quiz on Quiz {
    id
    updated_at
    created_at
    headline
    description
    question
    course {
      id
    }
    published_at
  }
`;

export const ARTICLE_SHORT_FORMAT = gql`
  fragment article on Article {
    id
    slug
    subtitle
    shortLead
    headline
    backgroundHEX
    draft
    coverImage {
      url
    }
    articleType {
      type
    }
    author {
      ...user
    }
    comments {
      ...comment
    }
    likes {
      ...like
    }
    interests {
      interestTitle
    }
    updated_at
    created_at
  }
`;

export const INTERESTS = gql`
  fragment interest on Interest {
    id
    interestTitle
    onboarding
  }
`;

export const USER = gql`
  fragment user on UsersPermissionsUser {
    id
    email
    username
    avatar {
      url
    }
  }
`;

export const USER_ALL_PARAMS = gql`
  fragment user on UsersPermissionsUser {
    id
    slug
    username
    email
    provider
    confirmed
    blocked
    role {
      id
      name
      description
      type
      permissions {
        id
        type
        controller
        action
        enabled
        policy
      }
    }
    phone
    birthDate
    avatar {
      url
    }
    coverImage {
      url
    }
    location
    aboutMe
    dashboardPassed
    feedPassed
    onboardingPassed
    jobTitle
    learningPassed
    instagram
    facebook
    knowledgeBasePassed
    articlesOwned {
      id
    }
    interests {
      ...interest
    }
    followers {
      id
      username
    }
    followings {
      id
      username
    }
    coursesPassed {
      id
    }
    departments {
      id
      name
    }
    events {
      id
      name
    }
    organizations {
      id
      name
    }
    saveds {
      id
      article {
        id
      }
    }
    coursesStarted {
      id
    }
    comments {
      id
    }
    likes {
      id
    }
  }
`;

export const LIKE = gql`
  fragment like on Like {
    id
    created_at
    updated_at
    user {
      ...user
    }
    articles {
      id
    }
    course {
      id
    }
    comment {
      id
    }
    published_at
  }
`;

export const COMMENT = gql`
  fragment comment on Comment {
    id
    created_at
    updated_at
    comment
    user {
      ...user
    }
    article {
      id
    }
    course {
      id
    }
    likes {
      id
    }
    published_at
  }
`;

export const LESSON = gql`
  fragment lesson on Lesson {
    id
    created_at
    updated_at
    headline
    contents
    published_at
    usersPassed {
      ...user
    }
  }
`;
