import React, { useCallback } from 'react';
import Badge from '../../common/Badge/Badge';
import People from '../../common/People/People';
import dayjs from 'dayjs';
import Svg from '../../common/SVG';

const UpcomingEventsItem = ({ event }) => {
  const { name, date, type, attendees, description } = event;

  const onClick = useCallback(() => {
    console.log('click');
  }, []);

  return (
    <div className="upcoming-events__item">
      <div className="upcoming-item-calendar">
        <span className="upcoming-item-calendar__date">{dayjs(date).format('DD')}</span>
        <span className="upcoming-item-calendar__month">{dayjs(date).format('MMMM')}</span>
      </div>
      <div className="upcoming-item-info">
        <div className="upcoming-item-event">
          <div className="upcoming-item-event-info">
            <span className="upcoming-item-event-info__name">{name}</span>
            <span className="upcoming-item-event-info__description">{description}</span>
            <span className="upcoming-item-event-info__status">
              <Badge
                label={type}
                size="xs"
                color={type === 'online' ? 'green' : 'gray'}
                isIcon={false}
              />
            </span>
          </div>
          <People amount={attendees.length} people={attendees} />
        </div>
        <div className="upcoming-item-event-button">
          <Svg name="add-to-calendar" size="md" />
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsItem;
