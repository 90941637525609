import React, { useEffect, useState } from 'react';
import KnowledgeBaseCompanyList from './KnowledgeBaseCompanyList.jsx';
import { nav } from '../../common/PageTemplate/articleTypesFilter';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';
import ButtonIcon from '../../common/Button/ButtonIcon';
import useViewBtn from '../../common/PageTemplate/useViewBtn';
import useOnlySort from '../../common/PageTemplate/useOnlySort';
import WelcomeBlock from '../../common/WelcomeBlock/WelcomeBlock';

const KnowledgeBaseCompany = () => {
  const token = useSelector(tokenSelector);
  const user = useSelector(userSelector);
  const { handleViewListBtn, handleViewGridBtn, activeButton } = useViewBtn();
  const [knowledgeBases, setKnowledgeBases] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/knowledge-bases`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setKnowledgeBases(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const { selectedSort, onClickSort } = useOnlySort(knowledgeBases, nav);

  return (
    <>
      <div className="page-template__container">
        <div className="knowledge-base-folder">
          <div
            className="knowledge-base-folder-banner"
            style={{ backgroundImage: `url(/images/knowledge-base-folder-bg.png)` }}
          >
            <div className="knowledge-base-folder-banner__text">Publication</div>
          </div>
        </div>
        {!Boolean(user?.progress?.knowledgeBasePassed) && (
          <WelcomeBlock
            type="knowledgeBasePassed"
            title="Welcome to Knowledge base"
            description="Here you can find folders to discover more about your company"
          />
        )}
        <div className="page-template__filter sort-and-view-btn">
          <DropdownLabelPage
            label={selectedSort}
            array={nav}
            selected={selectedSort}
            position="left"
            onClick={onClickSort}
          />
          <div className="page-filter-view-btn">
            <ButtonIcon
              icon="view-list-big"
              size="xxs"
              active={activeButton === 'viewListBtn'}
              onClick={handleViewListBtn}
            />
            <ButtonIcon
              icon="view-grid"
              size="xxs"
              active={activeButton === 'viewGridBtn'}
              onClick={handleViewGridBtn}
            />
          </div>
        </div>
        <KnowledgeBaseCompanyList
          list={knowledgeBases}
          viewListBtn={activeButton === 'viewListBtn'}
          viewGridBtn={activeButton === 'viewGridBtn'}
        />
      </div>
    </>
  );
};

export default KnowledgeBaseCompany;
