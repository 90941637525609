import React from 'react';
import cx from 'classnames';
import Svg from '../SVG';

const TagButton = (props) => {
  const { text, size = 'lg', icon, block, active, disabled, onClick, className } = props;

  const tagClassName = cx(
    'tag-button',
    active && 'active',
    size && `${size}`,
    block && 'block',
    disabled && 'disabled',
    className
  );

  return (
    <button type="button" className={tagClassName} onClick={onClick}>
      {icon && <Svg name={icon} />}

      <div className="tag-button__text">{text}</div>
    </button>
  );
};

export default TagButton;
