import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import useFilterAndSort from '../../common/PageTemplate/useFilterAndSort';
import { nav } from '../../common/PageTemplate/articleTypesFilter';
import PageTemplateArticleFormatsFilter from '../../common/PageTemplate/PageTemplateArticleFormatsFilter';
import TabsList from '../../common/Tab/TabsList';
import ArticleClassic from '../../components/Articles/types/ArticleClassic';
import Masonry from 'react-masonry-css';

const FeedContent = ({ feedArticles, feedArticlesFollowing }) => {
  const [activeTab, setActiveTab] = useState(0);
  const articles = activeTab === 0 ? feedArticles : feedArticlesFollowing;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
  });

  const {
    onSelect,
    selected,
    showOnlyAvailableTypes,
    labelFormats,
    selectedSort,
    onClickSort,
    getFilteredAndSortedArticles,
  } = useFilterAndSort(articles, nav);

  const renderArticles = useMemo(() => {
    return (
      <Masonry
        className="feed__page-grid-articles"
        breakpointCols={2}
        columnClassName="feed__page-grid-articles-col"
      >
        {getFilteredAndSortedArticles.map((article, index) => {
          return (
            <ArticleClassic
              key={index}
              article={article}
              sizeToShow={windowWidth < 1440 ? 'xs' : 'md'}
              // onClickBookmark={getBookmarks}
            />
          );
        })}
      </Masonry>
    );
  }, [getFilteredAndSortedArticles, windowWidth, articles, activeTab]);

  const feedTabs = [
    {
      label: 'Everything',
      content: renderArticles,
    },
    // {
    //   label: 'Following',
    //   content: renderArticles,
    // },
  ];

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div className="page-template__filter_with-tab">
        <TabsList tabs={feedTabs} activeTab={activeTab} setActiveTab={handleTabChange} />
        <PageTemplateArticleFormatsFilter
          labelFormats={_.upperFirst(labelFormats)}
          arrayFormats={showOnlyAvailableTypes}
          selectedFormats={selected}
          onClickFormats={(name) => onSelect(name)}
          labelSort={selectedSort}
          arraySort={nav}
          selectedSort={selectedSort}
          onClickSort={onClickSort}
          position="right"
        />
      </div>
      <div className="page-template__content">
        <div className="page-template__articles">{feedTabs[activeTab].content}</div>
      </div>
    </>
  );
};

export default FeedContent;
