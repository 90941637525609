export const tokenSelector = ({ authReducer }) => authReducer.token;
export const userSelector = ({ userReducer }) => userReducer.user;
export const showConfettiSelector = ({ confettiReducer }) => confettiReducer.showCongrats;
export const articlesFeedSelector = ({ articlesFeedReducer }) => articlesFeedReducer.articles;
export const articlesFollowingFeedSelector = ({ articlesFeedReducer }) =>
  articlesFeedReducer.articlesFollowing;
export const dashboardSelector = ({ dashboardReducer }) => dashboardReducer.dashboard;
export const dashboardArticlesSelector = ({ dashboardReducer }) => dashboardReducer.articles;
export const dashboardBookmarksSelector = ({ dashboardReducer }) => dashboardReducer.bookmarks;
export const dashboardEventsSelector = ({ dashboardReducer }) => dashboardReducer.events;
export const dashboardCoursesSelector = ({ dashboardReducer }) => dashboardReducer.courses;
export const bookmarksSelector = ({ bookmarksReducer }) => bookmarksReducer.bookmarks;
export const commentsSelector = ({ commentsReducer }) => commentsReducer.comments;
export const articleSelector = ({ articleReducer }) => articleReducer.article;
export const courseSelector = ({ courseReducer }) => courseReducer.course;
