import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Tab from '../../common/Tab/Tab';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { organizationsQueryGql } from '../../gql/query';
import { setIsUserId } from '../../gql/cache';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';

const SidebarKnowledgeBase = () => {
  const token = useSelector(tokenSelector);
  const [isOpenCompany, setIsOpenCompany] = useState(false);
  const [knowledgeBases, setKnowledgeBases] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/knowledge-bases`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setKnowledgeBases(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const {
    push,
    location: { pathname },
  } = useHistory();

  const getPath = pathname.split('/')[1];
  const getPathId = pathname.split('/')[2];

  const handleFolder = (slug) => {
    if (pathname === `/knowledge-base-company-folder/${slug}`) return;
    push(`/knowledge-base-company-folder/${slug}`);
  };

  const handleCompany = (link) => {
    push(link);
  };

  const handleOpenCompany = () => {
    setIsOpenCompany((isOpenCompany) => !isOpenCompany);
    if (pathname === '/knowledge-base-company') return;
    handleCompany('/knowledge-base-company');
  };

  const sortedAlphabetically = useCallback(
    (knowledgeBases) => {
      if (!knowledgeBases) return;
      return Object.values(knowledgeBases).sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    [knowledgeBases]
  );

  if (!knowledgeBases) return null;

  const subNav = sortedAlphabetically(knowledgeBases).map(({ name, id, slug }) => {
    return (
      <Tab
        onClick={() => handleFolder(slug)}
        key={id}
        typeLeft="item"
        textLeft={name}
        active={getPath === 'knowledge-base-company-folder' && getPathId === slug}
        color="whitePurple"
      />
    );
  });
  return (
    <div className="sidebar-sub-nav">
      <div>
        <Tab
          typeLeft="item"
          textLeft="Publication"
          iconLeft="info"
          typeRight="arrow"
          color="whitePurple"
          isOpen={isOpenCompany || getPath === 'knowledge-base-company-folder'}
          active={getPath === 'knowledge-base-company'}
          onClick={() => handleOpenCompany()}
        />
        {(isOpenCompany || getPath === 'knowledge-base-company-folder') && subNav}
      </div>
    </div>
  );
};

export default SidebarKnowledgeBase;
