import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Tab from '../../common/Tab/Tab';
import ShowMore from '../../common/ShowMore/ShowMore';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector, userSelector } from '../../store/selectors';
import useUserFollow from '../../common/useUserFollow';

const FeedSidebarPublishers = () => {
  const token = useSelector(tokenSelector);
  const user = useSelector(userSelector);
  const countPublishersToShow = 5;
  const [isPublishersShowMore, setPublishersShowMore] = useState(false);
  const [publishers, setPublishers] = useState([]);

  const { onClickFollow, onClickUnFollow } = useUserFollow();

  const ROUTES = {
    PUBLISHERS: `${process.env.REACT_APP_API_URL}/api/users`,
  };

  useEffect(() => {
    instanceFetch
      .get(ROUTES.PUBLISHERS, { authorization: `Bearer ${token}` })
      .then((response) => {
        setPublishers(response);
        response.map((item) => {
          if (user.id === item.id) {
            setPublishers(_.without(response, item));
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching publishers:', error);
      });
  }, []);

  if (!publishers) {
    return null;
  }

  const activeItems = isPublishersShowMore
    ? publishers
    : publishers.slice(0, countPublishersToShow);

  return (
    <>
      {activeItems.map(({ username, avatar, id }) => {
        const fullNameArray = _.words(username);
        const firstName = _.capitalize(fullNameArray[0] || '');
        const lastName = _.capitalize(fullNameArray[1] || '');
        const isFollowing =
          user?.followings && user?.followings.some((following) => following.id === id);
        return (
          <Tab
            key={id}
            typeRight="follow"
            avatar
            onClickFollow={() => (isFollowing ? onClickUnFollow(id) : onClickFollow(id))}
            avatarImg={avatar}
            textLeft={firstName + ' ' + lastName}
            btnFollowLabel
            color="transparentGray"
            active={isFollowing}
          />
        );
      })}
      {publishers.length > countPublishersToShow && (
        <ShowMore
          isShowMore={isPublishersShowMore}
          setShowMore={setPublishersShowMore}
          openLabel="Show less publishers"
          closeLabel="Show more publishers"
        />
      )}
    </>
  );
};

export default FeedSidebarPublishers;
