import React from 'react';
import Button from '../../common/Button/Button';

const LessonQuizFooter = ({
  retakeHandlerToStartCard,
  nextLesson,
  handleCreatePost,
  backToLesson,
  backToLessonHandler,
  coursePassed,
  failed,
  isLessonQuiz,
}) => {
  return (
    <div className="lesson-quiz__card-footer">
      {backToLesson || failed ? (
        <>
          <Button
            className="back-to-btn"
            iconLeft="arrow-left"
            text="Back to lesson"
            type="secondary"
            size="lg"
            onClick={backToLessonHandler}
          />
          <Button
            className="retake-btn"
            iconRight="reload"
            text="Retake quiz"
            type="primary"
            size="lg"
            onClick={retakeHandlerToStartCard}
          />
        </>
      ) : (
        <>
          <Button
            className="retake-btn"
            iconRight="reload"
            text="Retake quiz"
            type="secondary"
            size="md"
            onClick={retakeHandlerToStartCard}
          />
          <Button
            className="next-btn"
            iconRight="arrow-right-stroke"
            text={coursePassed ? 'Finish' : 'Next lesson'}
            type="primary"
            size="md"
            onClick={() => {
              !isLessonQuiz && handleCreatePost();
              nextLesson();
            }}
          />
        </>
      )}
    </div>
  );
};

export default LessonQuizFooter;
