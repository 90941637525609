import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';
import { getImageURL } from '../../helper';
import SubPagesFilterAndContent from '../../common/PageTemplate/SubPagesFilterAndContent';
import { isEmpty } from 'lodash';

const nav = ['Less completed first', 'More completed first', 'Last added', 'First added'];

const KnowledgeBaseCompanyFolder = () => {
  const token = useSelector(tokenSelector);
  const { slug } = useParams();
  const [knowledgeBase, setKnowledgeBase] = useState([]);

  const getKnowledgeBases = () => {
    instanceFetch
      .get(`${process.env.REACT_APP_API_URL}/api/knowledge-bases/slug/${slug}`, {
        authorization: `Bearer ${token}`,
      })
      .then((response) => {
        setKnowledgeBase(response);
      })
      .catch((error) => {
        console.error('Error fetching knowledgeBaseArticles:', error);
      });
  };
  useEffect(() => {
    getKnowledgeBases();
  }, []);

  const { name, description, coverImage } = knowledgeBase;

  const url = coverImage ? getImageURL(coverImage.url) : '/images/knowledge-base-folder-bg.png';

  const renderArticles = useMemo(() => {
    if (isEmpty(knowledgeBase)) return null;
    return (
      <SubPagesFilterAndContent
        articles={knowledgeBase?.articles ? Object.values(knowledgeBase.articles) : []}
        getBookmarks={getKnowledgeBases}
      />
    );
  }, [knowledgeBase]);

  if (isEmpty(knowledgeBase)) return null;

  return (
    <div className="page-template__container">
      <div className="knowledge-base-folder">
        <div className="knowledge-base-folder-banner" style={{ backgroundImage: `url(${url})` }}>
          <div className="knowledge-base-folder-banner__text">{name}</div>
        </div>
        {description && <div className="knowledge-base-folder__description">{description}</div>}

        {renderArticles}
      </div>
    </div>
  );
};

export default KnowledgeBaseCompanyFolder;
