import { useCallback } from 'react';
import { instanceFetch } from './instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';
import { getArticleAction } from '../store/actions/getArticleAction';

export const useSetLikes = ({ type, id }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    LIKE: `${process.env.REACT_APP_API_URL}/api/like/${type}/${id}`,
  };

  return useCallback(() => {
    instanceFetch
      .post(
        ROUTES.LIKE,
        {
          type: type,
          id: id,
        },
        { authorization: `Bearer ${token}` }
      )
      .then((response) => {
        // dispatch(getArticleAction(response));
      })
      .catch((error) => {
        console.error('Error post like:', error);
      });
  }, []);
};
