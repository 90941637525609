import { useCallback } from 'react';
import { instanceFetch } from './instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';
import { getArticleAction } from '../store/actions/getArticleAction';
import { getArticlesFeed } from '../store/actions/articleFeedAction';

export const useGetArticleBySlug = ({ slug }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    ARTICLE: `${process.env.REACT_APP_API_URL}/api/articles/slug/${slug}`,
  };

  return useCallback(() => {
    instanceFetch
      .get(ROUTES.ARTICLE, { authorization: `Bearer ${token}` })
      .then((article) => {
        dispatch(getArticleAction(article));
      })
      .catch((error) => {
        console.error('Error fetching article:', error);
      });
  }, [slug, dispatch]);
};

export const useGetAllArticlesFeed = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    ARTICLES_FEED: `${process.env.REACT_APP_API_URL}/api/articles`,
  };

  return useCallback(() => {
    instanceFetch
      .get(ROUTES.ARTICLES_FEED, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getArticlesFeed(response.data));
      })
      .catch((error) => {
        console.error('Error fetching articles feed:', error);
      });
  }, []);
};

export const useGetFollowingArticlesFeed = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    ARTICLES_FOLLOWING: `${process.env.REACT_APP_API_URL}/api/articles/following`,
  };

  return useCallback(() => {
    instanceFetch
      .get(ROUTES.ARTICLES_FOLLOWING, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getArticlesFeed(response.data));
      })
      .catch((error) => {
        console.error('Error fetching articles feed:', error);
      });
  }, []);
};
