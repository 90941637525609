import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import Avatar from '../Avatar/Avatar';
import DrawerModal from '../Drawer/Drawer';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';
import TextArea from 'antd/es/input/TextArea';
import Button from '../Button/Button';
import TabsList from '../Tab/TabsList';
import { useSetComments } from '../../helper/getComments';
import { useLocation } from 'react-router-dom';
import { useGetAllArticlesFeed, useGetArticleBySlug } from '../../helper/getArticle';
import { useGetCourseBySlug } from '../../helper/getCourse';

const CommentModal = ({ openModal, handleClick, id, type, comments }) => {
  const { pathname } = useLocation();
  const isMobile = false;
  const user = useSelector(userSelector);
  const { avatar, username } = user;
  const [commentAddInput, setAddCommentInput] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const path = pathname.split('/')[1];
  const isFeedPage = path === 'feed';
  const isOpenedArticle = path === 'article';
  const isCoursePage = pathname.split('/')[2] === 'your-learnings';

  const getAllArticlesFeed = useGetAllArticlesFeed();
  const getArticle = useGetArticleBySlug({ slug: pathname.split('/')[2] });
  const getCourse = useGetCourseBySlug({ slug: pathname.split('/')[3] });
  const handleAddComment = useSetComments({ type: type, id: id });

  const onClickComment = useCallback(() => {
    isFeedPage && getAllArticlesFeed();
    isOpenedArticle && getArticle();
    isCoursePage && getCourse();
  }, []);

  const onClick = useCallback(() => {
    handleAddComment(commentAddInput, setAddCommentInput, onClickComment);
  }, [comments, commentAddInput]);

  const sortCommentsByNewest = [...comments].sort((a, b) => {
    return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
  });

  const sortCommentsByOldest = [...comments].sort((a, b) => {
    return dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix();
  });

  const commentItem = useCallback((id, createdAt, comment, user) => {
    return (
      <div className="modal-comment__item" key={id}>
        <Avatar size="xlg" avatarImg={user?.avatar} author={user?.username} />
        <div className="modal-comment-item__comment">
          <div className="modal-comment-item__comment-info">
            <div className="modal-comment-item__comment-info-author">
              <span className="name">{user?.username}</span>
              <span className="date">{dayjs(createdAt).format('DD.MM.YYYY, hh:mm:ss')}</span>
            </div>
            {/*<span>ClapComment</span>*/}
          </div>
          <div className="modal-comment__item__content">{comment}</div>
        </div>
      </div>
    );
  }, []);

  const tabsComments = [
    {
      label: 'Newest',
      content: sortCommentsByNewest.map(({ id, comment, createdAt, user }) => {
        return commentItem(id, createdAt, comment, user);
      }),
    },
    {
      label: 'Oldest',
      content: sortCommentsByOldest.map(({ id, comment, createdAt, user }) => {
        return commentItem(id, createdAt, comment, user);
      }),
    },
  ];
  const onChangeCommentInput = (e) => {
    setAddCommentInput(e.target.value);
  };

  return (
    <DrawerModal
      width={420}
      placement="right"
      title="Comments"
      isDrawerVisible={openModal}
      handleClick={handleClick}
      className={cx('modal-comment', { mobile: isMobile })}
    >
      <div className="modal-add-comment">
        <div className="modal-add-comment__header">
          <div className="modal-add-comment__input">
            <Avatar size="xlg" avatarImg={avatar} author={username} />
            <TextArea
              className="input-comment"
              placeholder="Add a comment"
              autoSize={{ minRows: 1, maxRows: 5 }}
              onChange={onChangeCommentInput}
              value={commentAddInput}
            />
          </div>
          {commentAddInput && (
            <div className="modal__buttons">
              <Button
                text="Cancel"
                size="md"
                type="outline-purple"
                onClick={() => setAddCommentInput('')}
              />
              <Button text="Publish" size="md" type="primary" onClick={onClick} />
            </div>
          )}
        </div>
        {/*TODO: add tabs by sort comments*/}
        <div className="modal-add-comment__tab">
          <div className="tab-label-list">
            <TabsList tabs={tabsComments} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        </div>
      </div>

      <div className="modal-comment__list">{tabsComments[activeTab].content}</div>
    </DrawerModal>
  );
};

export default CommentModal;
