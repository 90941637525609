import { userQueryGql } from './query';
import { updateUserMutationGql } from './mutation';
import { followingsFollowersFragmentGql } from './fragment';
import { setIsUserId } from './cache';
import { useQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/selectors';

export const useProgress = () => {
  const data = useSelector(userSelector);
  if (data) {
    return {
      dashboardPassed: data?.progress?.dashboardPassed,
      feedPassed: data?.progress?.feedPassed,
      onboardingPassed: data?.progress?.onboardingPassed,
      learningPassed: data?.progress?.learningPassed,
      knowledgeBasePassed: data?.progress?.knowledgeBasePassed,
    };
  }

  return {
    dashboardPassed: null,
    feedPassed: null,
    onboardingPassed: null,
    learningPassed: null,
    knowledgeBasePassed: null,
  };
};

export const useFollowUser = (callback) => {
  const [updateFollowings, { client }] = useMutation(updateUserMutationGql, {
    onCompleted() {
      if (callback) {
        callback();
      }
    },
  });

  const { followings } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: followingsFollowersFragmentGql,
  });

  const isActive = useCallback(
    (id) => {
      return _.some(followings, { id });
    },
    [followings]
  );

  const itsMe = useCallback((id) => id === setIsUserId(), []);

  const onClick = useCallback(
    (id) => {
      const active = isActive(id);
      if (active) {
        return updateFollowings({
          variables: {
            input: {
              where: {
                id: setIsUserId(),
              },
              data: {
                followings: _.without(_.map(followings, 'id'), id),
              },
            },
          },
        });
      }
      return updateFollowings({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              followings: [..._.map(followings, 'id'), id],
            },
          },
        },
      });
    },
    [followings, isActive, updateFollowings]
  );

  return {
    onClick,
    isActive,
    itsMe,
  };
};
