import { useCallback } from 'react';
import { instanceFetch } from './instanceFetch';
import { useDispatch, useSelector } from 'react-redux';
import { getBookmarksAction } from '../store/actions/getBookmarksAction';
import { tokenSelector } from '../store/selectors';

export const useGetBookmarks = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    BOOKMARKS_LIST: `${process.env.REACT_APP_API_URL}/api/bookmarks`,
  };

  return useCallback(() => {
    instanceFetch
      .get(ROUTES.BOOKMARKS_LIST, { authorization: `Bearer ${token}` })
      .then((response) => {
        dispatch(getBookmarksAction(response));
      })
      .catch((error) => {
        console.error('Error fetching bookmarks:', error);
      });
  }, []);
};

export const useSetBookmarks = ({ type, id, user }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const ROUTES = {
    BOOKMARKS: `${process.env.REACT_APP_API_URL}/api/bookmarks/${type}/${id}`,
  };

  return useCallback((onClickBookmarkFn) => {
    instanceFetch
      .post(
        ROUTES.BOOKMARKS,
        {
          type: type,
          id: id,
          user: user.id,
        },
        { authorization: `Bearer ${token}` }
      )
      .then((response) => {
        dispatch(getBookmarksAction(response));
      })
      .then(() => {
        onClickBookmarkFn && onClickBookmarkFn();
      })
      .catch((error) => {
        console.error('Error post bookmarks:', error);
      });
  }, []);
};
