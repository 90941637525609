import React from 'react';
import cx from 'classnames';
import { getImageURL } from '../../../helper';
import Bookmark from '../../../common/Bookmark/Bookmark';
import Progressbar from '../../../common/Progressbar/Progressbar';
import { useHistory } from 'react-router-dom';
import TagLabel from '../../../common/Tag/TagLabel';

const ArticleProgress = ({ course, onClickBookmark }) => {
  const { push } = useHistory();
  const { coverImage, headline, id, slug, description, progress, mandatory } = course;

  const onClick = () => {
    push(`/dashboard/your-learnings/${slug}`);
  };
  return (
    <article className="article-item article-item__progress_row sm" onClick={onClick}>
      <div className="article-item__wrapper">
        <div className="article-item__content">
          <div
            className={cx('article-item__content-image', {
              'article-item__content-image--no-cover': !coverImage,
            })}
          >
            {coverImage?.url && <img src={getImageURL(coverImage.url)} alt="" />}
          </div>
          <div className="article-item__content-absolute">
            <div className="article-item__content-bookmark">
              <Bookmark type="course" article={course} onClickBookmark={onClickBookmark} />
            </div>
            {mandatory && (
              <div className="article-item__content-type">
                <TagLabel className="mandatory-article-label" text="Mandatory" icon="bell" />
              </div>
            )}
          </div>
        </div>
        <div className="article-item__content-body" onClick={onClick}>
          <div className="article-item__content-body__headers">
            <h3 className="article-item__content-body__title">{headline}</h3>
            {/*<div className="article-item__content-body__description">{description}</div>*/}
          </div>
          <div className="article-item__content-footer">
            <Progressbar progress={progress} />
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleProgress;
