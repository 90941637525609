import React from 'react';
import cx from 'classnames';

const Popups = ({ children, className }) => {
  const popupsClassName = cx('popups', className);

  return <div className={popupsClassName}>{children}</div>;
};

export default Popups;
