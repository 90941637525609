import { useMemo, useState } from 'react';
import { useTypes } from './articleTypesFilter';

const useFilterAndSort = (articles, nav) => {
  const availableTypes = useMemo(() => {
    const uniqueTypes = new Set();
    articles.forEach((article) => {
      if (article?.articleType?.name !== undefined) {
        uniqueTypes.add(article.articleType.name);
      }
    });
    return [...uniqueTypes];
  }, [articles]);

  const { types, onSelect, selected } = useTypes(availableTypes);

  const showOnlyAvailableTypes = useMemo(() => {
    return types.filter((type) => availableTypes.includes(type.name));
  }, [availableTypes, types]);

  const [selectedSort, setSelectedSort] = useState([nav[0]]);

  const onClickSort = (sort) => {
    setSelectedSort([sort]);
  };

  const getFilteredAndSortedArticles = useMemo(() => {
    const filteredArticles = articles?.articleType
      ? articles.filter((article) => selected.includes(article?.articleType?.name))
      : articles;
    let sortedArticles = filteredArticles;

    if (selectedSort.includes('Newest')) {
      sortedArticles = [...filteredArticles].sort(
        (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
      );
    } else if (selectedSort.includes('Oldest')) {
      sortedArticles = [...filteredArticles].sort(
        (a, b) => new Date(a.publishedAt) - new Date(b.publishedAt)
      );
    }

    return sortedArticles;
  }, [articles, selected, selectedSort]);

  const onlyAvailableLength = useMemo(
    () => (showOnlyAvailableTypes?.length ? `(${showOnlyAvailableTypes.length})` : ''),
    [showOnlyAvailableTypes]
  );

  const selectedFormatsByLength = useMemo(
    () =>
      selected?.length > 1
        ? `${selected.length} formats ${onlyAvailableLength}`
        : `${selected} ${onlyAvailableLength}`,
    [selected, onlyAvailableLength]
  );

  const labelFormats = useMemo(
    () =>
      selected?.length === showOnlyAvailableTypes?.length ? 'All Formats' : selectedFormatsByLength,
    [selected, showOnlyAvailableTypes, selectedFormatsByLength]
  );

  return {
    onSelect,
    selected,
    showOnlyAvailableTypes,
    labelFormats,
    selectedSort,
    onClickSort,
    getFilteredAndSortedArticles,
    // getFilteredAndSortedCourses,
  };
};

export default useFilterAndSort;
