import React from 'react';
import cx from 'classnames';
import Badge from '../Badge/Badge';
import Svg from '../SVG';

const Button = (props) => {
  const {
    text,
    type = 'primary',
    size = 'xs',
    badge,
    block,
    iconLeft,
    imageLeft,
    iconRight,
    iconCenter,
    disabled,
    className,
    onClick,
    children,
    typeHtml = 'button',
    ...otherProps
  } = props;

  const buttonClassName = cx(
    'btn',
    size && `${size}`,
    type && `${type}`,
    block && 'block',
    disabled && 'disabled',
    className
  );

  return (
    <button
      onClick={onClick}
      type={typeHtml}
      className={buttonClassName}
      disabled={disabled}
      {...otherProps}
    >
      {imageLeft && <img src={imageLeft} alt="" className="icon btn__icon-left" />}
      {iconLeft && <Svg className="btn__icon-left" name={iconLeft} />}
      {text && <span className="btn__text">{text}</span>}
      {children}
      {iconCenter && <Svg className="btn__icon-center" name={iconCenter} />}
      {iconRight && <Svg className="btn__icon-right" name={iconRight} />}

      {badge && <Badge className="btn-badge" label={badge} size="xs" />}
    </button>
  );
};

export default Button;
