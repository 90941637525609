import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { instanceFetch } from '../../helper/instanceFetch';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors';

export const nav = ['Newest', 'Oldest'];

export const nav2 = ['Less completed first', 'More completed first', 'Last added', 'First added'];

const ROUTES = {
  ARTICLE_TYPES: `${process.env.REACT_APP_API_URL}/api/article-types`,
};
export const useTypes = (availableToSelected) => {
  const token = useSelector(tokenSelector);
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    instanceFetch
      .get(ROUTES.ARTICLE_TYPES, { authorization: `Bearer ${token}` })
      .then((response) => {
        setData(response.data);
        if (availableToSelected) {
          setSelected(availableToSelected);
        } else {
          setSelected(response.data.map(({ slug }) => slug));
        }
      })
      .catch((error) => {
        console.log(error, 'ARTICLE_TYPES error');
      });
  }, []);

  const types = useMemo(() => {
    return data?.map(({ slug }) => {
      return {
        name: slug,
        icon: slug,
      };
    });
  }, [data]);

  const onSelect = useCallback(
    (item) => {
      if (selected.length === 1 && selected.includes(item)) {
        return;
      }
      let updatedSelected;
      if (_.includes(selected, item)) {
        updatedSelected = [..._.remove(selected, (i) => i !== item)];
      } else {
        updatedSelected = [...selected, item];
      }
      setSelected(updatedSelected);
    },
    [selected]
  );

  const onSelectOneType = useCallback((item) => {
    setSelected([item]);
  }, []);

  if (!data) return { types: [], selected: [], onSelect };

  return { types, selected, onSelect, onSelectOneType };
};
