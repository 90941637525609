import React, { useMemo, useState } from 'react';
import ArticleOpenedActions from './ArticleOpenedActions';

const SidebarActions = ({ article, type }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
  });

  // return useMemo(() => {
  //   return <ArticleOpenedActions article={article} showLabelAction={windowWidth > 1240} col type={type}/>;
  // }, [windowWidth, article]);

  return (
    <ArticleOpenedActions article={article} showLabelAction={windowWidth > 1240} col type={type} />
  );
};

export default SidebarActions;
