import React from 'react';
import cx from 'classnames';
import Svg from '../SVG';

const TagFormat = ({ text, icon, size = 'xs', className, pointerEvents }) => {
  const tagClass = cx('tag-label', size, pointerEvents && 'pointer-events', className);

  return (
    <div className={tagClass}>
      {icon && <Svg className="tag-label-icon" name={icon} />}
      {text}
    </div>
  );
};

export default TagFormat;
