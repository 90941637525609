import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import Svg from '../../common/SVG';
import LessonQuizStartCard from './LessonQuizStartCard';
import LessonQuizCard from './LessonQuizCard';
import LessonQuizFinishCard from './LessonQuizFinishCard';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import Button from '../../common/Button/Button';
import { isEmpty } from 'lodash';
import CourseQuizFinishCard from './CourseQuizFinishCard';

const LessonQuiz = ({ courseId, handleOpenQuiz, nextLesson, quiz, coursePassed, isLessonQuiz }) => {
  const isMobile = false;
  const [startCard, setStartCard] = useState(true);
  const [finishedQuiz, setFinishedQuiz] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [retake, setRetake] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [useAllTries, setUseAllTries] = useState(false);

  const startCardHandler = () => {
    setStartCard((startCard) => !startCard);
  };

  const retakeHandlerToStartCard = () => {
    setRetake(false);
    setStartCard(true);
    setFinishedQuiz(false);
    setUseAllTries(false);
  };

  const lessonCloseHandler = () => {
    setIsModalVisible((isModalVisible) => !isModalVisible);
  };

  const quizWrapperClass = cx('lesson-quiz__card-wrapper', {
    'quiz-finished': finishedQuiz,
  });

  if (isEmpty(quiz)) {
    return null;
  }
  const quizObject = [...quiz.questions];
  const quizObjectSorted = quizObject.sort(() => Math.random() - 0.5);

  const lessonQuizClass = cx('lesson-quiz', {
    mobile: isMobile,
    finished: finishedQuiz,
    regular: !startCard && !finishedQuiz,
  });

  const modalChildren = (
    <>
      <div className={lessonQuizClass}>
        {!finishedQuiz && (
          <>
            <button className="btn lesson-quiz-btn-back" onClick={lessonCloseHandler}>
              <Svg name="back-to" />
            </button>
            {isMobile && (
              <div className="lesson-quiz__card-description-mobile">
                <span>{quiz.headline}</span>
              </div>
            )}
          </>
        )}
        <div className="lesson-quiz__cards">
          <div className={quizWrapperClass}>
            {!finishedQuiz ? (
              startCard ? (
                <LessonQuizStartCard
                  quizTitle={quiz.headline}
                  startCardHandler={startCardHandler}
                />
              ) : (
                <LessonQuizCard
                  quizTitle={quiz.headline}
                  questionsData={quizObjectSorted}
                  setTotalScore={setTotalScore}
                  retake={retake}
                  retakeHandlerToStartCard={retakeHandlerToStartCard}
                  setFinishedQuiz={setFinishedQuiz}
                  nextLesson={nextLesson}
                  handleOpenQuiz={handleOpenQuiz}
                  setUseAllTries={setUseAllTries}
                />
              )
            ) : isLessonQuiz ? (
              <LessonQuizFinishCard
                handleOpenQuiz={handleOpenQuiz}
                retakeHandlerToStartCard={retakeHandlerToStartCard}
                nextLesson={nextLesson}
                totalScore={totalScore}
                courseId={courseId}
                coursePassed={coursePassed}
                isLessonQuiz={isLessonQuiz}
                failed={useAllTries}
              />
            ) : (
              <CourseQuizFinishCard
                handleOpenQuiz={handleOpenQuiz}
                retakeHandlerToStartCard={retakeHandlerToStartCard}
                nextLesson={nextLesson}
                totalScore={totalScore}
                courseId={courseId}
                coursePassed={coursePassed}
                failed={useAllTries}
              />
            )}
          </div>
        </div>
      </div>
      {isModalVisible && (
        <ModalWrapper
          className="modal_quiz"
          showCloseIcon={false}
          isModalVisible={isModalVisible}
          handleClick={lessonCloseHandler}
          modalWidth={isMobile && '100%'}
        >
          <div className="m-1 modal__title">Are you sure you want to quit the quiz?</div>
          <div className="s-6 modal__description">The progress will be lost</div>
          <div className="modal__buttons">
            <Button text="No, cancel" size="md" type="secondary" onClick={lessonCloseHandler} />
            <Button text="Yes, close" size="md" type="primary" onClick={handleOpenQuiz} />
          </div>
        </ModalWrapper>
      )}
    </>
  );
  return ReactDOM.createPortal(modalChildren, document.body);
};

export default LessonQuiz;
