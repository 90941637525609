import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Svg from '../SVG';
import { useSetLikes } from '../../helper/getLikes';
import { useLocation } from 'react-router-dom';
import { useGetAllArticlesFeed, useGetArticleBySlug } from '../../helper/getArticle';
import { useGetCourseBySlug } from '../../helper/getCourse';

export const LikesIcon = ({ active }) => {
  return active ? <Svg className="clap-filled" name="clap-filled" /> : <Svg name="clap" />;
};
const LikesCount = ({ count }) => {
  if (count === 0) return <span className="clap-count">Clap</span>;
  return <span className="clap-count">{count && <span>{count} claps</span>}</span>;
};

const Likes = ({ article, showLabelAction = false, type }) => {
  const { pathname } = useLocation();
  const { id, slug } = article;
  const [articleID, setArticleID] = useState(id);
  const { liked, likesCount } = article;
  const path = pathname.split('/')[1];
  const isFeedPage = path === 'feed';
  const isCoursePage = pathname.split('/')[2] === 'your-learnings';

  const handleSetLikes = useSetLikes({ type: type, id: articleID });

  const getAllArticlesFeed = useGetAllArticlesFeed();
  const getArticle = useGetArticleBySlug({ slug: slug });
  const getCourse = useGetCourseBySlug({ slug: slug });

  useEffect(() => {
    setArticleID(id);
  }, [id]);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const toRequest = isFeedPage
        ? getAllArticlesFeed()
        : isCoursePage
        ? getCourse()
        : getArticle();
      handleSetLikes(toRequest);
    },
    [article, path, articleID]
  );

  return useMemo(() => {
    return (
      <div className="claps-container" onClick={onClick}>
        <LikesIcon active={liked} />
        {showLabelAction && <LikesCount count={likesCount} />}
      </div>
    );
  }, [showLabelAction, article]);
};

export default Likes;
